export const songs = [
  {
    artist: "CAKE",
    name: "The Distance",
    youtubeId: "JH6il8U3EU0"
  },
  { artist: "Avril Lavigne", name: "Girlfriend", youtubeId: "g0TiuFwX0r8" },
  { artist: "Anders Nilsen", name: "Salsa Tequila", youtubeId: "CgZDGe_5xS4" },
  { artist: "Linkin Park", name: "Bleed It Out", youtubeId: "bSwzZoLCoZ0" },
  { artist: "Def Leppard", name: "Animal", youtubeId: "1mgpMqQKEh8" },
  { artist: "Green Day", name: "When I Come Around", youtubeId: "06HcqK2vvlw" },
  { artist: "Phil Collins", name: "I Don't Care Anymore", youtubeId: "C6PNc9KN50M" },
  { artist: "Bag Raiders", name: "Shooting Stars", youtubeId: "O-MQC_G9jTU" },
  { artist: "Lifehouse", name: "Hanging By A Moment", youtubeId: "NPgN0j6fm0w" },
  { artist: "Toby Fox", name: "Undertale (Undertale)", youtubeId: "8CeYNJ-s0Kw" },
  { artist: "Spice Girls", name: "Wannabe", youtubeId: "tscL_I2v7pU" },
  { artist: "AC/DC", name: "Back In Black", youtubeId: "pAgnJDJN4VA" },
  { artist: "Metallica", name: "For Whom The Bell Tolls", youtubeId: "B_HSa1dEL9s" },
  { artist: "Fun.", name: "Some Nights", youtubeId: "O8g_iLP_9go" },
  { artist: "Snoop Dogg, Pharrell Williams", name: "Drop It Like It's Hot", youtubeId: "ldjajgAHAu4" },
  { artist: "Kendrick Lamar, MC Eiht", name: "m.A.A.d city", youtubeId: "AuikIJZpt_8" },
  { artist: "The Police", name: "Every Breath You Take", youtubeId: "-SaUrDhp034" },
  { artist: "Bon Jovi", name: "Runaway", youtubeId: "kb2DgAF3egQ" },
  { artist: "Judas Priest", name: "You've Got Another Thing Coming", youtubeId: "LNyIhirtXUI" },
  { artist: "Gorillaz", name: "Feel Good Inc.", youtubeId: "NxxjLD2pmlk" },
  { artist: "R.E.M.", name: "Man On The Moon", youtubeId: "CO5GaVJiRwo" },
  { artist: "Sunstroke Project, Olia Tira", name: "Run Away", youtubeId: "TMku5KCNkb4" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Overture (Halo: Reach)", youtubeId: "3A7WIn06ceA" },
  { artist: "Harry Belafonte", name: "Day O (Banana Boat Song)", youtubeId: "YO7M0Hx_1D8" },
  { artist: "Ted Nugent", name: "Stranglehold", youtubeId: "MRj5KT3742o" },
  { artist: "Electric Light Orchestra", name: "Is It Alright", youtubeId: "zbiTRjCuGi0" },
  { artist: "Bee Gees", name: "Stayin' Alive", youtubeId: "W_dDNNEdIJg" },
  { artist: "_ensnare_", name: "I Drop Gems", youtubeId: "4dqsl-bbqVU" },
  { artist: "R.E.M.", name: "It's The End Of The World As We Know It (And I Feel Fine)", youtubeId: "-s97_bBGobQ" },
  { artist: "Ben Prunty", name: "MilkyWay (Explore) (FTL: Faster than Light)", youtubeId: "lwJ8L0QL_qY" },
  { artist: "The Tubes", name: "She's A Beauty", youtubeId: "k6YzNrA6Elk" },
  { artist: "Jack Stauber's Micropop", name: "Inchman", youtubeId: "6AHrEjteu-E" },
  { artist: "Warren Zevon", name: "Werewolves of London", youtubeId: "lh0w01S7Jnk" },
  { artist: "Toby Fox", name: "MEGALOVANIA (Undertale)", youtubeId: "0FCvzsVlXpQ" },
  { artist: "KT Tunstall", name: "Black Horse And The Cherry Tree", youtubeId: "qWKcNoerynI" },
  { artist: "Kenny Loggins", name: "Footloose (From \"Footloose\" Soundtrack)", youtubeId: "HvropLxYb5c" },
  { artist: "A Flock Of Seagulls", name: "I Ran (So Far Away) (Single Edit)", youtubeId: "cEgMousgsqQ" },
  { artist: "Mat Zo", name: "Defined", youtubeId: "DZF46bDWljQ" },
  { artist: "The Hives", name: "Tick Tick Boom", youtubeId: "NwVwsW0XZvY" },
  { artist: "Home", name: "We're Finally Landing", youtubeId: "M-0TB9JMpdE" },
  { artist: "Aerosmith", name: "Rag Doll", youtubeId: "Xp9WFBsi3NE" },
  { artist: "Selena Gomez", name: "Come & Get It", youtubeId: "APXB7op_aRQ" },
  { artist: "Linda Ronstadt", name: "Blue Bayou", youtubeId: "IDBVW4BXZPo" },
  { artist: "Third Eye Blind", name: "How's It Going to Be", youtubeId: "2dITTT-OaWE" },
  { artist: "Soft Cell", name: "Tainted Love / Where Did Our Love Go (Extended Version / Medley)", youtubeId: "q84psZX6MbA" },
  { artist: "Empire of the Sun", name: "Wandering Star (Dumb and Dumber To)", youtubeId: "KvuCinEJJHw" },
  { artist: "Eiffel 65", name: "Blue (Da Ba Dee) (Video Edit)", youtubeId: "kyzIQKuSqBs" },
  { artist: "Eric Prydz", name: "Pjanoo (Radio Edit)", youtubeId: "zVtEBow2E7g" },
  { artist: "U2", name: "Beautiful Day", youtubeId: "V1j_MA3daLI" },
  { artist: "Daft Punk", name: "Harder, Better, Faster, Stronger", youtubeId: "JhulBGMA7G4" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Delta Halo Suite (Halo 2)", youtubeId: "dvuyErXPLdo" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Halo (Halo: Combat Evolved)", youtubeId: "Bzrnw4niuTA" },
  { artist: "The Weather Girls", name: "It's Raining Men", youtubeId: "4x6leDGV7gs" },
  { artist: "Styx", name: "Fooling Yourself (The Angry Young Man)", youtubeId: "apTy_Wez4V4" },
  { artist: "Dire Straits", name: "Sultans of Swing", youtubeId: "xZatdZ_bqOc" },
  { artist: "Justice", name: "D.A.N.C.E.", youtubeId: "tCnBrrnOefs" },
  { artist: "Katy Perry", name: "Roar", youtubeId: "6nJuUdc2qNg" },
  { artist: "King Harvest, Sherman Kelly", name: "Dancing in the Moonlight", youtubeId: "0eHBLHVHjWg" },
  { artist: "Ted Poley, Tony Harnell, Jun Senoue", name: "Escape From The City ... for City Escape (Sonic Adventure 2)", youtubeId: "qR6mTJRbcaE" },
  { artist: "Madonna", name: "Like a Prayer", youtubeId: "IaHQYTm14Kc" },
  { artist: "Pegboard Nerds", name: "Disconnected", youtubeId: "5gUYr7LYKkw" },
  { artist: "Toad The Wet Sprocket", name: "Rock And Roll All Nite", youtubeId: "HlDyCgS8E3w" },
  { artist: "NOMA", name: "Brain Power (Long Version)", youtubeId: "cVP8D_FwPU8" },
  { artist: "Yes", name: "Roundabout", youtubeId: "cPCLFtxpadE" },
  { artist: "Pitbull, TJR", name: "Don't Stop the Party", youtubeId: "52vzL3sFUT0" },
  { artist: "Eve 6", name: "Inside Out", youtubeId: "WkcQt-1j-SE" },
  { artist: "Blackstreet, Dr. Dre, Queen Pen", name: "No Diggity", youtubeId: "Fiu9h8ZTPoQ" },
  { artist: "Michael Jackson", name: "P.Y.T. (Pretty Young Thing)", youtubeId: "y32ejtuxSjM" },
  { artist: "Duran Duran", name: "Is There Something I Should Know?", youtubeId: "zUk-XaYDwIU" },
  { artist: "Oingo Boingo", name: "Dead Man's Party", youtubeId: "9cF8XQPQ1lU" },
  { artist: "Bananarama", name: "I Heard a Rumour", youtubeId: "4KI7twZHJdw" },
  { artist: "Pat Benatar", name: "Heartbreaker", youtubeId: "nsSx6nXpBMg" },
  { artist: "John Fogerty", name: "Centerfield", youtubeId: "LVTXn9Y3dM0" },
  { artist: "Loona", name: "Vamos a la Playa (Radio Edit)", youtubeId: "xmDc-1IrYRU" },
  { artist: "Electric Light Orchestra", name: "Showdown", youtubeId: "dV0-Cho26IY" },
  { artist: "Smash Mouth", name: "Walkin' On The Sun", youtubeId: "v-4pqHjpguE" },
  { artist: "Hechizeros Band", name: "El Sonidito", youtubeId: "iN3th1Gt_XY" },
  { artist: "Lynyrd Skynyrd", name: "Sweet Home Alabama", youtubeId: "iL-jC7XyLeo" },
  { artist: "deadmau5 ft Rob Swire", name: "Ghosts 'n' Stuff", youtubeId: "pb-EwykPTv8" },
  { artist: "The Animals", name: "House Of The Rising Sun", youtubeId: "s_Kecv6eJNM" },
  { artist: "Joan Jett & the Blackhearts", name: "I Love Rock 'N Roll", youtubeId: "d9jhDwxt22Y" },
  { artist: "Danny Elfman", name: "Breakfast Machine (From \"Pee Wee's Big Adventure\")", youtubeId: "KoLOtsm_Jow" },
  { artist: "Scatman John", name: "Scatman (ski-ba-bop-ba-dop-bop)", youtubeId: "Eht9W25tDZo" },
  { artist: "Howard Jones", name: "Things Can Only Get Better", youtubeId: "4XkdBMxPCkc" },
  { artist: "Orleans", name: "Still the One", youtubeId: "SdfW_2frXnE" },
  { artist: "The Babys", name: "Isn't It Time", youtubeId: "K52eWjgIBm0" },
  { artist: "Aerosmith", name: "Love In An Elevator", youtubeId: "EsUUr22n8N4" },
  { artist: "Marvin Gaye", name: "Too Busy Thinking About My Baby", youtubeId: "eyZgAbXfsL4" },
  { artist: "Rush", name: "Tom Sawyer", youtubeId: "QJ4-9tAf9UQ" },
  { artist: "3 Doors Down", name: "Kryptonite", youtubeId: "xPU8OAjjS4k" },
  { artist: "Seal", name: "Fly Like an Eagle", youtubeId: "QVvUbBHd7Zg" },
  { artist: "Pink Floyd", name: "Mother", youtubeId: "xe3NUKCnZp4" },
  { artist: "M.O.O.N.", name: "Dust (Hotline Miami 2)", youtubeId: "phL6fDiYNJk" },
  { artist: "Kiss", name: "Rock And Roll All Nite", youtubeId: "f-S3X2saSwM" },
  { artist: "Arcade Fire", name: "Everything Now", youtubeId: "6vG5WqYJViY" },
  { artist: "Fleetwood Mac", name: "Go Your Own Way", youtubeId: "MTIkFwMuiTw" },
  { artist: "New Order", name: "Bizarre Love Triangle", youtubeId: "bKVbB_qZQcU" },
  { artist: "Marietta", name: "Destination Unknown (From \"Top Gun\" Original Soundtrack)", youtubeId: "XfYmlhz3mIk" },
  { artist: "Neon Trees", name: "Everybody Talks", youtubeId: "JwlHM8TvFE4" },
  { artist: "Bonnie Tyler", name: "Holding Out for a Hero (From \"Footloose\" Soundtrack)", youtubeId: "qgHVh5jspsA" },
  { artist: "Blind Melon", name: "No Rain", youtubeId: "mjN4dMvC0Gs" },
  { artist: "Mike Posner", name: "I Took A Pill In Ibiza (Seeb Remix)", youtubeId: "Ah0srVZq9ac" },
  { artist: "John Mellencamp", name: "Jack & Diane", youtubeId: "DEF52ShgEMU" },
  { artist: "Britney Spears", name: "Toxic", youtubeId: "tVdr_JWmnsA" },
  { artist: "The White Stripes", name: "Seven Nation Army", youtubeId: "D_QLxj8jCF0" },
  { artist: "Billy Squier", name: "Everybody Wants You", youtubeId: "q0aLYYn_6tM" },
  { artist: "Above & Beyond feat. Zoë Johnston", name: "Sahara Love", youtubeId: "DUFBQqhxR8c" },
  { artist: "Fleetwood Mac", name: "Landslide", youtubeId: "k4M53xndqiU" },
  { artist: "American Authors", name: "Best Day Of My Life", youtubeId: "BeOa6kRA6aw" },
  { artist: "Modest Mouse", name: "Dashboard", youtubeId: "21euaOPVneM" },
  { artist: "Kristofer Maddigan", name: "Clip Joint Calamity", youtubeId: "RRCiA55yZe0" },
  { artist: "The Verve", name: "Bitter Sweet Symphony", youtubeId: "JnRw8bXVbPI" },
  { artist: "twenty one pilots", name: "Car Radio", youtubeId: "Z_Jp2mlzEjw" },
  { artist: "Matchbox Twenty", name: "Unwell", youtubeId: "VTgMFcFIH9k" },
  { artist: "Robbie Williams", name: "It's Only Us", youtubeId: "0eRNOMcMUHQ" },
  { artist: "Creedence Clearwater Revival", name: "Fortunate Son", youtubeId: "r5NzAksjfDI" },
  { artist: "Boston", name: "Foreplay / Long Time", youtubeId: "QlMUlpju3qo" },
  { artist: "Gwen Stefani, Eve", name: "Rich Girl", youtubeId: "cem_uAOsouE" },
  { artist: "Bloc Party", name: "Helicopter", youtubeId: "deM4ueJpMbY" },
  { artist: "Queens Of The Stone Age", name: "3's & 7's", youtubeId: "HBo_R-MRazU" },
  { artist: "Hootie & The Blowfish", name: "Only Wanna Be with You", youtubeId: "62F4rAa-CK0" },
  { artist: "Carole King", name: "Sweet Seasons", youtubeId: "PUBO1G1c55U" },
  { artist: "Jimmy Buffett", name: "Cheeseburger In Paradise", youtubeId: "7PfZ4QVqCs8" },
  { artist: "John Mellencamp", name: "Hurts So Good", youtubeId: "NcdLoKlqlQU" },
  { artist: "Toby Fox", name: "Rude Buster (Deltarune)", youtubeId: "GPL5Hkl11IQ" },
  { artist: "Al Stewart", name: "Song on the Radio", youtubeId: "VaSx2k_dfSc" },
  { artist: "twenty one pilots", name: "Ode to Sleep", youtubeId: "Vp3WQ_iWh3c" },
  { artist: "Guns N' Roses", name: "Knockin' On Heaven's Door", youtubeId: "f8OHybVhQwc" },
  { artist: "Foo Fighters", name: "The Pretender", youtubeId: "BMMGwtklEeE" },
  { artist: "Alanis Morissette", name: "You Oughta Know", youtubeId: "pcYGTI_Bfv8" },
  { artist: "Ellis", name: "Orbit", youtubeId: "uHNkmXAe2js" },
  { artist: "M.O.O.N.", name: "Hydrogen (Hotline Miami)", youtubeId: "SNE2oCZH_4k" },
  { artist: "Hyper Potions", name: "Time Trials (Sonic Mania)", youtubeId: "BkfUUV5R8iA" },
  { artist: "Fountains Of Wayne", name: "Stacy's Mom", youtubeId: "sT_hnNDstnE" },
  { artist: "Crazy Frog", name: "Axel F", youtubeId: "_M1iPYsHZvU" },
  { artist: "David Guetta, Akon, Aliaune \"Akon\" Thiam", name: "Sexy Chick (feat. Akon)", youtubeId: "dZIYHbiCqfs" },
  { artist: "Miike Snow", name: "Paddling Out", youtubeId: "d7FHg0cw4RA" },
  { artist: "Europe", name: "The Final Countdown", youtubeId: "NNiTxUEnmKI" },
  { artist: "Alanis Morissette", name: "Ironic", youtubeId: "E1ILlhZPAoc" },
  { artist: "Earth, Wind & Fire", name: "September", youtubeId: "3cKtSlsYVEU" },
  { artist: "Bob Seger & The Silver Bullet Band", name: "Old Time Rock And Roll", youtubeId: "eMY1ewg-e9w" },
  { artist: "Electric Light Orchestra", name: "Turn to Stone", youtubeId: "7gncH7WOQvY" },
  { artist: "Lady Gaga", name: "Bad Romance", youtubeId: "fXgLARPprRc" },
  { artist: "No Doubt", name: "Don't Speak", youtubeId: "Q7uiJcxIsK4" },
  { artist: "Level 42", name: "Something About You", youtubeId: "WtKxEmuSxBc" },
  { artist: "Blackalicious", name: "Alphabet Aerobics", youtubeId: "zlynqOgBAUw" },
  { artist: "Katy Perry, Snoop Dogg", name: "California Gurls", youtubeId: "ru3tYeyIOFk" },
  { artist: "Elton John", name: "Crocodile Rock", youtubeId: "75r0nQu-hMs" },
  { artist: "SEGA, Takenobu Mitsuyoshi, Takenobu Mitsuyoshi", name: "Let's Go Away (Daytona USA)", youtubeId: "5ToNSY-yG_M" },
  { artist: "Green Day", name: "Wake Me up When September Ends", youtubeId: "ulRXvH8VOl8" },
  { artist: "The Human League", name: "[Keep Feeling] Fascination", youtubeId: "CrnGQ2-bri4" },
  { artist: "Foreigner", name: "Hot Blooded", youtubeId: "FusX37u4cNA" },
  { artist: "No Doubt", name: "It's My Life", youtubeId: "bpUgcEc3iLw" },
  { artist: "Rush", name: "The Spirit Of Radio", youtubeId: "wPBinohXHLc" },
  { artist: "Basshunter", name: "DotA (Radio Edit)", youtubeId: "TuNyDfHKzlk" },
  { artist: "Eminem", name: "My Name Is", youtubeId: "I_bBRzWzkC8" },
  { artist: "John Denver", name: "Take Me Home, Country Roads (Original Version)", youtubeId: "c0dvabcWVo0" },
  { artist: "The Doobie Brothers", name: "Black Water", youtubeId: "RsP3rFJJk1Y" },
  { artist: "Ides Of March", name: "Vehicle", youtubeId: "bq95nZBbNHI" },
  { artist: "Aerosmith", name: "I Don't Want to Miss a Thing", youtubeId: "JkK8g6FMEXE" },
  { artist: "Steve Miller Band", name: "Abracadabra", youtubeId: "dKCV8gzSlYw" },
  { artist: "Michael Jackson", name: "The Way You Make Me Feel", youtubeId: "VtM-rHPwN7o" },
  { artist: "Guns N' Roses", name: "Welcome To The Jungle", youtubeId: "0CNPR2qNzxk" },
  { artist: "Styx", name: "Mr. Roboto", youtubeId: "3n_7acSpvuo" },
  { artist: "Approaching Nirvana", name: "305", youtubeId: "uEOavBkGV9k" },
  { artist: "Owl City", name: "Fireflies", youtubeId: "KWLGyeg74es" },
  { artist: "Red Hot Chili Peppers", name: "Under the Bridge", youtubeId: "4UnU3r0M3zg" },
  { artist: "England Dan & John Ford Coley", name: "I'd Really Love to See You Tonight", youtubeId: "P8RShs00yR0" },
  { artist: "Sweet", name: "Ballroom Blitz", youtubeId: "7Aze726qAwA" },
  { artist: "The O'Jays", name: "For the Love of Money", youtubeId: "7KlxInHeH5Q" },
  { artist: "Steve Miller Band", name: "Take The Money And Run", youtubeId: "ido6NrjGi2o" },
  { artist: "Glenn Frey", name: "The Heat Is On (From \"Beverly Hills Cop\" Soundtrack)", youtubeId: "uKWgYKa2Fh0" },
  { artist: "Bowling For Soup", name: "High School Never Ends", youtubeId: "xPub2CzBEVM" },
  { artist: "Matchbox Twenty", name: "Back 2 Good", youtubeId: "kBHnTyiquAA" },
  { artist: "Shania Twain", name: "Up! (Red Version)", youtubeId: "PH6mxRhp1ZQ" },
  { artist: "twenty one pilots", name: "Morph", youtubeId: "rYuBU7YzZ-c" },
  { artist: "Steve Miller Band", name: "Jet Airliner", youtubeId: "JHa1hiFYbFQ" },
  { artist: "Fatboy Slim", name: "The Rockafeller Skank", youtubeId: "wD6jCMn5nq0" },
  { artist: "Gwen Stefani", name: "Hollaback Girl", youtubeId: "7YQESUr8Cxc" },
  { artist: "Bobby \"Boris\" Pickett", name: "Monster Mash", youtubeId: "bRLML36HnzU" },
  { artist: "Pete Townshend", name: "Let My Love Open The Door", youtubeId: "9dw29xpA0Qg" },
  { artist: "Tokyo Machine", name: "Hype (Just Shapes & Beats)", youtubeId: "74Yir_AP2Ts" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "One Final Effort (Halo 3)", youtubeId: "5b8zZmSZC_0" },
  { artist: "Pat Benatar", name: "Shadows Of The Night", youtubeId: "J6hZ4NwFCg0" },
  { artist: "Pink Floyd", name: "Time", youtubeId: "pgXozIma-Oc" },
  { artist: "Dave Matthews Band", name: "Ants Marching", youtubeId: "nE5p3acSloM" },
  { artist: "Supertramp", name: "Goodbye Stranger", youtubeId: "u8pVZ5hTGJQ" },
  { artist: "Rob Thomas", name: "Lonely No More", youtubeId: "cg48698R0KA" },
  { artist: "Steve Miller Band", name: "The Joker", youtubeId: "tYTgtsCm0DA" },
  { artist: "Barenaked Ladies", name: "Pinch Me (Radio Version)", youtubeId: "udBWSiRL7yU" },
  { artist: "Foo Fighters", name: "Learn to Fly", youtubeId: "HJMLLKgknvk" },
  { artist: "[HD] The Protomen", name: "Act II", youtubeId: "gkLvpt9Z3fA" },
  { artist: "The Who", name: "Eminence Front", youtubeId: "BfrUQA2tb6M" },
  { artist: "Led Zeppelin", name: "Black Dog", youtubeId: "XIiu0JI3I5g" },
  { artist: "Rascal Flatts", name: "Life is a Highway", youtubeId: "Zh-ZUrc-aLI" },
  { artist: "Men At Work", name: "Down Under", youtubeId: "hfmxO-HQ5rU" },
  { artist: "Men At Work", name: "Who Can It Be Now?", youtubeId: "Lc1Ll-euRSg" },
  { artist: "The Outfield, John Frederick Spinks", name: "Colosseum", youtubeId: "EE-_HB-Z3sM" },
  { artist: "Pitbull, John Ryan", name: "Fireball", youtubeId: "AN2-czGsblw" },
  { artist: "Pink Floyd", name: "The Happiest Days Of Our Lives", youtubeId: "VyTarjUjNMQ" },
  { artist: "Pink Floyd", name: "On The Run", youtubeId: "2sUyk5zSbhM" },
  { artist: "Mark Morrison", name: "Return of the Mack", youtubeId: "1ZfjB5P7RS0" },
  { artist: "Mötley Crüe", name: "Kickstart My Heart", youtubeId: "WqOlHt--fOI" },
  { artist: "Foreigner", name: "I Want to Know What Love Is", youtubeId: "3DpHXUuxq1Q" },
  { artist: "Justice", name: "Genesis", youtubeId: "VKzWLUQizz8" },
  { artist: "Marc Cohn", name: "Walking in Memphis", youtubeId: "dUh1DSIsUOY" },
  { artist: "Lady Gaga", name: "Poker Face", youtubeId: "oG-4Uvhm4lI" },
  { artist: "Home", name: "Resonance", youtubeId: "Qe2t3FyS9uA" },
  { artist: "Cage The Elephant", name: "Ain't No Rest for the Wicked (Borderlands)", youtubeId: "e2Z0sON2UPc" },
  { artist: "Cutting Crew", name: "[I Just] Died In Your Arms", youtubeId: "2wf-MNzSbpA" },
  { artist: "Bing Crosby, The Andrews Sisters", name: "Mele Kalikimaka (Merry Christmas)", youtubeId: "BtdEufWBDo8" },
  { artist: "José Feliciano", name: "Feliz Navidad", youtubeId: "N8NcQzMQN_U" },
  { artist: "Vince Guaraldi Trio", name: "Linus And Lucy", youtubeId: "StDWreYwV6o" },
  { artist: "Bob Rivers", name: "The Twelve Pains of Christmas", youtubeId: "AMUVVnA0B_w" },
  { artist: "Amy Grant", name: "Emmanuel", youtubeId: "bITQdYQenv0" },
  { artist: "David Foster", name: "Carol Of The Bells", youtubeId: "EfZBnMIih_M" },
  { artist: "Gayla Peevey", name: "I Want a Hippopotamus for Christmas (Hippo the Hero)", youtubeId: "faZZ8GWxifw" },
  { artist: "John Debney", name: "Main Title (Elf)", youtubeId: "N-FSezFRB8M" },
  { artist: "Bobby Helms", name: "Jingle Bell Rock", youtubeId: "Z0ajuTaHBtM" },
  { artist: "Andy Williams", name: "It's the Most Wonderful Time of the Year", youtubeId: "9JQU_3Mx6xY" },
  { artist: "Lou Monte", name: "Dominick the Donkey (The Italian Christmas Donkey)", youtubeId: "D8PnHSV7mag" },
  { artist: "Trans-Siberian Orchestra", name: "Christmas Eve / Sarajevo 12/24 (Instrumental)", youtubeId: "4Hd8jLgpaZQ" },
  { artist: "Bing Crosby", name: "It's Beginning to Look Like Christmas", youtubeId: "z3vMisNaqd8" },
  { artist: "Trans-Siberian Orchestra", name: "Christmas Canon", youtubeId: "Das4PWWLecg" },
  { artist: "Jackson 5", name: "Santa Claus Is Coming To Town", youtubeId: "nYmPZrvThW8" },
  { artist: "Amy Grant", name: "Mister Santa", youtubeId: "t2b_fXVj53w" },
  { artist: "Jackson 5", name: "I Saw Mommy Kissing Santa Claus", youtubeId: "U5resSXzMiI" },
  { artist: "Nat King Cole", name: "The Christmas Song (Merry Christmas To You)", youtubeId: "1Jp3XIqTR2w" },
  { artist: "The Beach Boys", name: "Little Saint Nick", youtubeId: "5dyXN74aZGQ" },
  { artist: "Bing Crosby, Ken Darby Singers, John Scott Trotter and His Orchestra", name: "White Christmas (1947 Version)", youtubeId: "t_xq3Bj_tas" },
  { artist: "The Drifters, Clyde McPhatter, Bill Pinkney", name: "White Christmas (feat. Clyde McPhatter & Bill Pinkney)", youtubeId: "mFfFWodmFwk" },
  { artist: "Brenda Lee", name: "Rockin' Around The Christmas Tree", youtubeId: "1qYz7rfgLWE" },
  { artist: "Thurl Ravenscroft", name: "You're a Mean One, Mr. Grinch", youtubeId: "cGSneujgGT8" },
  { artist: "NewSong", name: "The Christmas Shoes", youtubeId: "GJcPVB-we7g" },
  { artist: "Mariah Carey", name: "All I Want For Christmas Is You", youtubeId: "yXQViqx6GMY" },
  { artist: "Tom Petty And The Heartbreakers", name: "Learning To Fly", youtubeId: "zG5FPc-qDv0" },
  { artist: "Bronski Beat", name: "Smalltown Boy", youtubeId: "8PGppDW8kD0" },
  { artist: "M.C. Hammer", name: "U Can't Touch This", youtubeId: "gDCRrvtY78g" },
  { artist: "Madonna, Justin Timberlake, Timbaland", name: "4 Minutes (feat. Justin Timberlake and Timbaland)", youtubeId: "cySuerPVnQA" },
  { artist: "Styx", name: "Come Sail Away", youtubeId: "yhKe20WWLAY" },
  { artist: "Maroon 5", name: "Harder To Breathe", youtubeId: "Back4okF0Yg" },
  { artist: "Kungs, Cookin' On 3 Burners", name: "This Girl (Kungs Vs. Cookin' On 3 Burners)", youtubeId: "kNtk-CnpL-Y" },
  { artist: "Earth, Wind & Fire", name: "Let's Groove", youtubeId: "ltjio4AM2uw" },
  { artist: "Billy Squier", name: "The Stroke", youtubeId: "5KOUEX_oG9k" },
  { artist: "OK Go", name: "This Too Shall Pass", youtubeId: "jWsntzxlsPM" },
  { artist: "Nitro Fun", name: "Cheat Codes (Just Shapes & Beats)", youtubeId: "2Y9uceuIAxE" },
  { artist: "REO Speedwagon", name: "Can't Fight This Feeling", youtubeId: "z_WYwfrMFko" },
  { artist: "Skid Row", name: "Youth Gone Wild", youtubeId: "x6d07j_IL1M" },
  { artist: "Katy Perry", name: "Firework", youtubeId: "S23IzDZBuBQ" },
  { artist: "Steve Winwood", name: "The Finer Things", youtubeId: "aU4tFpwYK0s" },
  { artist: "Maroon 5", name: "She Will Be Loved", youtubeId: "LHBbWBX_azA" },
  { artist: "Bob Seger & The Silver Bullet Band", name: "Turn The Page (Live At Cobo Hall, Detroit/1975)", youtubeId: "twZZdk2OuDk" },
  { artist: "Nickelback", name: "Photograph", youtubeId: "LzejHuBS_D4" },
  { artist: "Caramella Girls", name: "Caramelldansen (Speedy Mixes)", youtubeId: "UwTt2Ox_0AE" },
  { artist: "Natalie Imbruglia", name: "Torn", youtubeId: "rIGAt5yvfmw" },
  { artist: "Of Monsters and Men", name: "Little Talks", youtubeId: "KtDmEy7R_c8" },
  { artist: "Eminem", name: "Lose Yourself (From \"8 Mile\" Soundtrack)", youtubeId: "Wj7lL6eDOqc" },
  { artist: "Kendrick Lamar", name: "HUMBLE", youtubeId: "ZZxHN6hpoeQ" },
  { artist: "ABBA", name: "Voulez-Vous", youtubeId: "0X2mn7Sk9lQ" },
  { artist: "Metro Station", name: "Shake It", youtubeId: "ED3Uc-GSgzY" },
  { artist: "Redbone", name: "Come and Get Your Love", youtubeId: "rsf_gTZG4cI" },
  { artist: "Bon Jovi", name: "Wanted Dead Or Alive", youtubeId: "xNRaDavYo1M" },
  { artist: "Daft Punk", name: "Technologic", youtubeId: "R5uHYAIkzgU" },
  { artist: "Iron Butterfly", name: "In-A-Gadda-Da-Vida", youtubeId: "Tfpn3wHoNGA" },
  { artist: "Prince & The Revolution", name: "Purple Rain", youtubeId: "S6Y1gohk5-A" },
  { artist: "Pink Floyd", name: "The Great Gig In The Sky", youtubeId: "mPGv8L3a_sY" },
  { artist: "Magic Sword", name: "In The Face Of Evil", youtubeId: "ZyHfUqs17WI" },
  { artist: "deadmau5 feat. Chris James", name: "The Veldt", youtubeId: "uiUAq4aVTjY" },
  { artist: "Sheryl Crow", name: "Soak Up The Sun", youtubeId: "88HeyQnV6Qk" },
  { artist: "Cheap Trick", name: "The Flame", youtubeId: "km0uoY9u5yk" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Brothers in Arms (Halo: Combat Evolved)", youtubeId: "aQHZCP0ew88" },
  { artist: "Sheryl Crow", name: "Real Gone", youtubeId: "HdimnI4Xtc0" },
  { artist: "Good Charlotte", name: "I Don't Wanna Be In Love (Dance Floor Anthem)", youtubeId: "jtePSuQB5S0" },
  { artist: "Elton John", name: "Tiny Dancer", youtubeId: "aqlGlaNlcWE" },
  { artist: "The Killers", name: "All These Things That I've Done", youtubeId: "AUAxlOfw2O0" },
  { artist: "Red Hot Chili Peppers", name: "Can't Stop", youtubeId: "D4INE2zO9OU" },
  { artist: "R.E.M.", name: "Everybody Hurts", youtubeId: "tKRznjaIIyw" },
  { artist: "Tom Petty And The Heartbreakers", name: "Don't Do Me Like That", youtubeId: "LwgY3qB7PBI" },
  { artist: "Mat Zo", name: "Caller ID", youtubeId: "bCx3wjP-2zQ" },
  { artist: "E-40", name: "Choices (Yup)", youtubeId: "UloQngR6kjQ" },
  { artist: "Van Halen", name: "Runnin' with the Devil", youtubeId: "KLRO4W9pNrQ" },
  { artist: "Beastie Boys", name: "Fight For Your Right", youtubeId: "pYoN9OraOWM" },
  { artist: "Prince & The Revolution", name: "I Would Die 4 U", youtubeId: "hkNl3pq1twE" },
  { artist: "Linkin Park", name: "What I've Done", youtubeId: "Jrprk5dNboI" },
  { artist: "Daft Punk, Pharrell Williams, Nile Rodgers", name: "Get Lucky (feat. Pharrell Williams and Nile Rodgers)", youtubeId: "4D7u5KF7SP8" },
  //{ artist: "Quiet Riot", name: "Cum on Feel the Noize", youtubeId: "XQ5mygB97nM" },
  { artist: "Evanescence", name: "Bring Me To Life", youtubeId: "-eGM0IJc70Y" },
  { artist: "Bruce Springsteen", name: "Glory Days", youtubeId: "GLBnj159q3s" },
  { artist: "twenty one pilots", name: "Holding on to You", youtubeId: "_t8HWppY4T0" },
  { artist: "Linkin Park", name: "Numb", youtubeId: "u-IcadiJCrE" },
  { artist: "ABBA", name: "Waterloo", youtubeId: "9y-8ZiAJiQo" },
  { artist: "M.O.O.N.", name: "Crystals (Hotline Miami)", youtubeId: "AVblOqZBlJw" },
  { artist: "Deniece Williams", name: "Let's Hear It for the Boy (From \"Footloose\" Soundtrack)", youtubeId: "vwXx5BgXyJ8" },
  { artist: "Laura Branigan", name: "Gloria", youtubeId: "fxf2y8Tl9Ts" },
  { artist: "The Vapors", name: "Turning Japanese", youtubeId: "nhD9gyRSz0g" },
  { artist: "The Heavy", name: "How You Like Me Now", youtubeId: "PgeluJltn8w" },
  { artist: "Rod Stewart", name: "Love Touch", youtubeId: "VJSj6nRgkto" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Neon Night (Halo 3: ODST)", youtubeId: "VoyyNpRC3Eg" },
  { artist: "The Black Keys", name: "Lonely Boy", youtubeId: "9DYPfItb2fk" },
  { artist: "The Allman Brothers Band", name: "Midnight Rider", youtubeId: "Z8zk7XKyoE8" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Perilous Journey (Halo: Combat Evolved)", youtubeId: "0obow-wqPb0" },
  { artist: "Guns N' Roses", name: "Sympathy For The Devil", youtubeId: "ldCbVPMzyD4" },
  { artist: "Daði Freyr", name: "Think About Things", youtubeId: "yrtksZu_O5E" },
  { artist: "Scorpions", name: "Still Loving You", youtubeId: "cjx-y8SCPjI" },
  { artist: "Guns N' Roses", name: "Paradise City", youtubeId: "T0ZmErXkbxE" },
  { artist: "The Black Keys", name: "Howlin' for You", youtubeId: "Zs3cyuXSFII" },
  { artist: "The Doobie Brothers, James Taylor", name: "Takin' It to the Streets", youtubeId: "lkJDaHmP67s" },
  { artist: "Pearl Jam", name: "Even Flow", youtubeId: "u7T2OR-O2Vk" },
  { artist: "Badfinger", name: "Baby Blue", youtubeId: "33oAuvbRg-c" },
  { artist: "Depeche Mode", name: "Personal Jesus", youtubeId: "JP1ijanTrdY" },
  { artist: "Martin O’Donnell, Michael Salvatori, Stan LePard", name: "The Menagerie (Halo 3: ODST)", youtubeId: "So1nJcCFWfw" },
  { artist: "Steely Dan", name: "Reelin' In The Years", youtubeId: "91XTZ92zs2w" },
  { artist: "Nena", name: "99 Luftballons", youtubeId: "AT_0zXw2rRo" },
  { artist: "Kansas", name: "Point of Know Return", youtubeId: "Qkr5moYbLkg" },
  { artist: "Gwen Stefani, Akon", name: "The Sweet Escape", youtubeId: "BsqG3_FpRzE" },
  { artist: "Alphaville", name: "Forever Young", youtubeId: "W5guhMw_EH0" },
  { artist: "Katy Perry", name: "The One That Got Away", youtubeId: "64wxAbbWNag" },
  { artist: "Katrina & The Waves", name: "Walking On Sunshine", youtubeId: "HmR2IZjuMVc" },
  { artist: "Five for Fighting", name: "100 Years", youtubeId: "pA-YIAYKB4I" },
  { artist: "The Alan Parsons Project", name: "Sirius", youtubeId: "OkC_oi0ksuw" },
  { artist: "U2", name: "With Or Without You", youtubeId: "oNvWDP_GkiY" },
  { artist: "The Wallflowers", name: "One Headlight", youtubeId: "yjbzUEJONNU" },
  { artist: "KC & The Sunshine Band", name: "Boogie Shoes", youtubeId: "x8CthqzCgP4" },
  { artist: "Cher", name: "Half-Breed", youtubeId: "RQ0lpiXQ__Y" },
  { artist: "Rod Stewart", name: "Young Turks", youtubeId: "EbZP13EbpgM" },
  { artist: "Foo Fighters", name: "Everlong", youtubeId: "AxuTd9rwEHQ" },
  { artist: "Blondie", name: "Heart Of Glass", youtubeId: "A4MrL7ncX9M" },
  { artist: "Guster", name: "Satellite", youtubeId: "2CL4yXVmVBE" },
  { artist: "Herman's Hermits", name: "I'm Into Something Good", youtubeId: "MDUCOwp1p60" },
  { artist: "Def Leppard", name: "Armageddon It", youtubeId: "GgUnqQbNzoE" },
  { artist: "Seal", name: "Kiss from a Rose", youtubeId: "49ubX7n1BaQ" },
  { artist: "Haddaway", name: "What Is Love", youtubeId: "OFNrN_6Ta5I" },
  { artist: "REO Speedwagon", name: "Keep on Loving You", youtubeId: "XKc7z-enzmA" },
  { artist: "LMFAO", name: "Sexy And I Know It", youtubeId: "NqiTCzvTk6I" },
  { artist: "Styx", name: "Why Me", youtubeId: "g4bCHctqmHs" },
  { artist: "Enrique Iglesias, Descemer Bueno, Gente De Zona", name: "Bailando (Spanish Version)", youtubeId: "Iz0hjRq40ko" },
  { artist: "Billy Ocean", name: "Caribbean Queen (No More Love On the Run)", youtubeId: "VOiZC020nl0" },
  { artist: "Rise Against", name: "Savior", youtubeId: "vbMh38KGZMM" },
  { artist: "Queen", name: "Radio Ga Ga", youtubeId: "u6Hep6WlWfY" },
  { artist: "Neil Diamond", name: "Brother Love's Travelling Salvation Show", youtubeId: "6pCxPNuokfM" },
  { artist: "Jerry Martin", name: "Rush Hour (SimCity 4)", youtubeId: "LRM61x0UU8E" },
  { artist: "The Clash", name: "Train in Vain (Stand by Me)", youtubeId: "b3akwA6CJ8U" },
  { artist: "White Town", name: "Your Woman", youtubeId: "3NoIusrv9OA" },
  { artist: "Snow Patrol", name: "Chasing Cars", youtubeId: "sgj04v5sDm4" },
  { artist: "Aaron Smith, Krono, Luvli", name: "Dancin (Krono Remix)", youtubeId: "LjtvF_UuRIU" },
  { artist: "Journey", name: "Don't Stop Believin'", youtubeId: "1k8craCGpgs" },
  { artist: "Duran Duran", name: "Hungry Like the Wolf", youtubeId: "qCeyLsDHsKk" },
  { artist: "John Mayer", name: "No Such Thing", youtubeId: "0LWAGLMBB2M" },
  { artist: "Greg Kihn Band", name: "The Breakup Song", youtubeId: "AffLdombeOk" },
  { artist: "Evanescence", name: "My Immortal", youtubeId: "RxrTVf2vkLs" },
  { artist: "Eric Clapton", name: "Cocaine", youtubeId: "8QYdIswhbY4" },
  { artist: "The Black Keys", name: "Gold on the Ceiling", youtubeId: "8tPsaSPraks" },
  { artist: "David Bowie", name: "Let's Dance", youtubeId: "gHlwJvPv9C0" },
  { artist: "Foreigner", name: "Urgent", youtubeId: "XIATjH1AxVc" },
  { artist: "Billy Joel", name: "Only the Good Die Young", youtubeId: "ZQ_m236f_eM" },
  { artist: "Spin Doctors", name: "Two Princes", youtubeId: "a748-dgSB10" },
  { artist: "Barenaked Ladies", name: "If I Had $1,000,000", youtubeId: "aynCgnbbgbM" },
  { artist: "Led Zeppelin", name: "Immigrant Song", youtubeId: "5eHkjPCGXKQ" },
  { artist: "No Doubt", name: "Just A Girl", youtubeId: "liSwJDJtmPw" },
  { artist: "Rick James", name: "Super Freak", youtubeId: "wnKc4zi0MLA" },
  { artist: "El Chombo", name: "Chacarrón", youtubeId: "omkF2Rdnfj4" },
  { artist: "Jonathan Coulton", name: "Want You Gone (Portal 2)", youtubeId: "dVVZaZ8yO6o" },
  { artist: "Post Malone, Swae Lee", name: "Sunflower (Spider-Man: Into the Spider-Verse)", youtubeId: "r7Rn4ryE_w8" },
  { artist: "The Immortals", name: "Techno Syndrome (Mortal Kombat)", youtubeId: "kWO2AuJT67w" },
  { artist: "Katy Perry", name: "Last Friday Night (T.G.I.F.)", youtubeId: "6RMt2II3QXQ" },
  { artist: "Gary Numan", name: "Cars", youtubeId: "MkeO7ThL8yg" },
  { artist: "Kevin Rudolf, Lil Wayne", name: "Let It Rock", youtubeId: "1lAdfEsf4p8" },
  { artist: "Nickelback", name: "This Afternoon", youtubeId: "nsxFrIAxdHQ" },
  { artist: "Electric Light Orchestra", name: "Don't Bring Me Down", youtubeId: "kW0HZy8J0v0" },
  { artist: "Hanson", name: "MMMBop", youtubeId: "S3Oyipl36YQ" },
  { artist: "Florence + The Machine", name: "You've Got The Love", youtubeId: "92NvPOFaKpA" },
  { artist: "WALK THE MOON", name: "Shut Up and Dance", youtubeId: "GGsuLVlLObc" },
  { artist: "Bruce Springsteen", name: "Hungry Heart", youtubeId: "SZ32qT7e1_M" },
  { artist: "ABBA", name: "Dancing Queen", youtubeId: "YkLLcIKhJ64" },
  { artist: "Fitz and The Tantrums", name: "HandClap", youtubeId: "5AtkWnBjGBU" },
  { artist: "Linkin Park", name: "In the End", youtubeId: "Un8Uv1psMOI" },
  { artist: "Sound Remedy & Nitro Fun", name: "Turbo Penguin", youtubeId: "IQOlBJXSB2s" },
  { artist: "Two Door Cinema Club, Alex Trimble, Kevin Baird, Sam Hallidy", name: "I Can Talk", youtubeId: "GckxMO6VILM" },
  { artist: "The Rolling Stones", name: "Start Me Up", youtubeId: "VRwn2DeXhLs" },
  { artist: "Lady Gaga, Colby O'Donis", name: "Just Dance", youtubeId: "ACNwMzZejQQ" },
  { artist: "The Killers", name: "When You Were Young", youtubeId: "s2gtQJiM5Zg" },
  { artist: "Cascada", name: "Everytime We Touch", youtubeId: "84sHTvn6xf8" },
  { artist: "Scorpions", name: "No One Like You", youtubeId: "qKhorc1aYro" },
  { artist: "Joji", name: "Run", youtubeId: "3Mt-q96kvl0" },
  { artist: "Jimmy Buffett", name: "Margaritaville", youtubeId: "5BuqBkR4384" },
  { artist: "Pitbull, Ne-Yo, Afrojack, Nayer", name: "Give Me Everything", youtubeId: "E2lX1ZovtcA" },
  { artist: "James Brown", name: "Get Up Offa That Thing", youtubeId: "2ATmDmLAMWw" },
  { artist: "Pink Floyd", name: "Comfortably Numb", youtubeId: "x-xTttimcNk" },
  { artist: "Scandal, Patty Smyth", name: "The Warrior", youtubeId: "hN9-PjWVD8A" },
  { artist: "George Harrison", name: "When We Was Fab", youtubeId: "wlIKlyjpvYw" },
  { artist: "The Last Goodnight", name: "Pictures Of You", youtubeId: "zmUtXrDBVtE" },
  { artist: "Beastie Boys", name: "Brass Monkey", youtubeId: "acY2VSskD80" },
  { artist: "Lady Gaga, Beyoncé", name: "Telephone", youtubeId: "KfSVnUvKyZ0" },
  { artist: "Flobots", name: "Handlebars", youtubeId: "3w0qD6Atvo4" },
  { artist: "Steve Perry", name: "Oh Sherrie", youtubeId: "krGdPoiCQ8I" },
  { artist: "Genesis", name: "Jesus He Knows Me", youtubeId: "_gbMnDK1nCw" },
  { artist: "Pink Floyd", name: "Another Brick In The Wall (Part 2)", youtubeId: "zz8frWcmthA" },
  { artist: "Two Door Cinema Club, Alex Trimble, Sam Hallidy, Kevin Baird", name: "What You Know", youtubeId: "VsOR4Z87dNw" },
  { artist: "Bowling For Soup", name: "Punk Rock 101", youtubeId: "UQQRJ8UfExU" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Luck (Halo 3)", youtubeId: "UbMxGrTVovg" },
  { artist: "Queen", name: "Good Old-Fashioned Lover Boy", youtubeId: "pSbC09ul3gk" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Greatest Journey (Halo 3)", youtubeId: "4Q0Jbv6ciRU" },
  { artist: "Joan Jett & the Blackhearts", name: "I Hate Myself for Loving You", youtubeId: "V6m7b5xJq7Y" },
  { artist: "Counting Crows, Vanessa Carlton", name: "Big Yellow Taxi", youtubeId: "c8CxCoJcZbI" },
  { artist: "3 Doors Down", name: "Be Like That", youtubeId: "HHpMtWtgUvc" },
  { artist: "Don Omar, Lucenzo", name: "Danza Kuduro", youtubeId: "BbWY-avjDto" },
  { artist: "Alice Cooper", name: "School's Out", youtubeId: "37ALmuMSHEI" },
  { artist: "The Heavy", name: "Short Change Hero (Borderlands 2)", youtubeId: "GjTTB6yII4o" },
  { artist: "Aerosmith", name: "Dream On", youtubeId: "89dGC8de0CA" },
  { artist: "Bon Jovi", name: "It's My Life", youtubeId: "bY3vXr7fm8k" },
  { artist: "Stray Cats", name: "Rock This Town", youtubeId: "v9TP5V1b6EA" },
  { artist: "Jimmy Eat World", name: "The Middle", youtubeId: "rubpIfLPzvU" },
  { artist: "Pat Benatar", name: "Love Is A Battlefield", youtubeId: "vo6PgC0cP4c" },
  { artist: "Carl Douglas", name: "Kung Fu Fighting", youtubeId: "it3E6vtExSE" },
  { artist: "Billy Joel", name: "Big Shot", youtubeId: "71GxVw2yWf4" },
  { artist: "Jason Mraz", name: "The Remedy (I Won't Worry)", youtubeId: "R7EYYL4Q0Ao" },
  { artist: "Supergrass", name: "Alright", youtubeId: "2QrYNgjuBJc" },
  { artist: "Puddle Of Mudd", name: "Blurry", youtubeId: "ui-TR4U9WOs" },
  { artist: "Elton John", name: "Saturday Night’s Alright (For Fighting)", youtubeId: "AhnZEmnuzgM" },
  { artist: "Toto", name: "Georgy Porgy", youtubeId: "HX_oR3qwwro" },
  { artist: "Fox Stevenson", name: "Dreamland", youtubeId: "6asic_8eJoo" },
  { artist: "Daft Punk, Pharrell Williams", name: "Lose Yourself to Dance (feat. Pharrell Williams)", youtubeId: "iU7oF4OXZSE" },
  { artist: "Poison", name: "Nothin' But A Good Time", youtubeId: "uHbneqNR2pE" },
  { artist: "The Cars", name: "Just What I Needed", youtubeId: "TB4gJ7shVD0" },
  { artist: "Third Eye Blind", name: "Semi-Charmed Life", youtubeId: "gjdLAsnR_Ws" },
  { artist: "Jon Bellion, Travis Mendes", name: "Guillotine", youtubeId: "58p4OTAP91c" },
  { artist: "Yolanda Be Cool, DCup", name: "We No Speak Americano (Original Mix)", youtubeId: "lygg5Ju0nNU" },
  { artist: "Steely Dan", name: "Peg", youtubeId: "u_pt3khMRFs" },
  { artist: "Lee Greenwood", name: "God Bless The U.S.A.", youtubeId: "zR0lxM-IyUs" },
  { artist: "Journey", name: "Faithfully", youtubeId: "YalgKfrOdsI" },
  { artist: "Stevie Nicks", name: "Edge of Seventeen", youtubeId: "b5bgQjTmm3g" },
  { artist: "The Buggles", name: "Video Killed The Radio Star", youtubeId: "i4RfVRhhhNI" },
  { artist: "Chris Tilton", name: "Building the Foundation (SimCity 2013)", youtubeId: "d_QvSN07S5c" },
  { artist: "Mötley Crüe", name: "Wild Side", youtubeId: "MVkTYD_3XPA" },
  { artist: "Queen", name: "You're My Best Friend", youtubeId: "iIT89N78uX8" },
  { artist: "Toto", name: "Africa", youtubeId: "QAo_Ycocl1E" },
  { artist: "Raspberries", name: "Go All The Way", youtubeId: "rtp8WSAzrGw" },
  { artist: "Rihanna", name: "Disturbia", youtubeId: "wmGjajXDHKo" },
  { artist: "Phil Collins", name: "Sussudio", youtubeId: "q9xNhcvZ6UI" },
  { artist: "Third Eye Blind", name: "Jumper (1998 Edit)", youtubeId: "tjK8zK3TMJc" },
  { artist: "Pitbull, Christina Aguilera", name: "Feel This Moment", youtubeId: "r90Wwl2MPq0" },
  { artist: "Pitbull, Ke$ha", name: "Timber", youtubeId: "GcYF1xnmZTI" },
  { artist: "Tom Petty And The Heartbreakers", name: "American Girl", youtubeId: "8v8-RSyuUeE" },
  { artist: "Commodores", name: "Easy", youtubeId: "aCsZ3_db_Fk" },
  { artist: "Chicago", name: "25 or 6 to 4", youtubeId: "JTzFh1dCoGg" },
  { artist: "Genesis", name: "Land of Confusion", youtubeId: "CKrsAje9lxg" },
  { artist: "Pink Floyd", name: "Breathe (In The Air)", youtubeId: "Vddl9TK5RqU" },
  { artist: "Bowling For Soup", name: "1985", youtubeId: "H1Pb-cRbspA" },
  { artist: "Steve Miller Band", name: "Jungle Love", youtubeId: "qGnCyMc_X-4" },
  { artist: "Paul Engemann", name: "Scarface (Push It To The Limit)", youtubeId: "TgHi0LxiPfg" },
  { artist: "Night Ranger", name: "Sister Christian", youtubeId: "D7S2qCKb6XE" },
  { artist: "Genesis", name: "That's All", youtubeId: "0X0EsVa7YvY" },
  { artist: "Fleetwood Mac", name: "Little Lies", youtubeId: "ZWuViMR_A1o" },
  { artist: "Shania Twain", name: "Man! I Feel Like A Woman!", youtubeId: "YGJBMFS02oY" },
  { artist: "Panic! At The Disco", name: "I Write Sins Not Tragedies", youtubeId: "Vy0mqXuBMY4" },
  { artist: "Of Monsters and Men", name: "Empire", youtubeId: "9Wa7Ok7RXvE" },
  { artist: "Goo Goo Dolls", name: "Here Is Gone", youtubeId: "cLl24ww3vgE" },
  { artist: "The Doors", name: "Break on Through (To the Other Side)", youtubeId: "NFeUko-lQHg" },
  { artist: "AC/DC", name: "Hells Bells", youtubeId: "etAIpkdhU9Q" },
  { artist: "Danzel", name: "Pump It Up (Radio Edit)", youtubeId: "lG0nkCy2IrI" },
  { artist: "Dschinghis Khan", name: "Moskau", youtubeId: "XM7vBXJAK-8" },
  { artist: "twenty one pilots", name: "Stressed Out", youtubeId: "K1FlAphL2p8" },
  { artist: "Talking Heads", name: "This Must Be the Place (Naive Melody)", youtubeId: "fsccjsW8bSY" },
  { artist: "Hollywood Principle, Kev Frey", name: "Seeing What's Next (Kev Frey Remix)", youtubeId: "JFRAatKpGII" },
  { artist: "2Pac, Roger Troutman, Dr. Dre", name: "California Love (Original Version)", youtubeId: "LRt6TdSvHag" },
  { artist: "Fiction Factory", name: "[Feels Like] Heaven", youtubeId: "mR56DXA_Cg0" },
  { artist: "Finger Eleven", name: "One Thing", youtubeId: "1xVew7oWidQ" },
  { artist: "The Revivalists", name: "Wish I Knew You", youtubeId: "ZMtw1n8tL4I" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Traffic Jam (Halo 3: ODST)", youtubeId: "kOa9FXAKA2o" },
  { artist: "Barenaked Ladies", name: "One Week", youtubeId: "gES4Z1ll9nI" },
  { artist: "Electric Light Orchestra", name: "Last Train to London", youtubeId: "u8axoBf5QWo" },
  { artist: "Journey", name: "Open Arms", youtubeId: "3ByIYof4mqo" },
  { artist: "Matchbox Twenty", name: "Real World", youtubeId: "8u8gnS1hqiM" },
  { artist: "Red Hot Chili Peppers", name: "Dani California", youtubeId: "4FkfyssnHqU" },
  { artist: "Nine Days", name: "Absolutely (Story of a Girl) (Radio Mix)", youtubeId: "ZwHMBZ34eQQ" },
  { artist: "The Killers", name: "Mr. Brightside", youtubeId: "m2zUrruKjDQ" },
  { artist: "The Cascades", name: "Rhythm of the Rain", youtubeId: "AtEKXIQCwYw" },
  { artist: "Chumbawamba", name: "Tubthumping", youtubeId: "yW5oTzftgjY" },
  { artist: "Princeton", name: "LvUrFR3NZ (Halo 3)", youtubeId: "P4tEHl_P98E" },
  { artist: "Styx", name: "Too Much Time On My Hands", youtubeId: "MlzFvOzl75Y" },
  { artist: "Deadmau5", name: "Strobe (Club Edit)", youtubeId: "oUmvPgH5iD0" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "A Walk in the Woods (Halo: Combat Evolved)", youtubeId: "y6DdRv6NIyY" },
  { artist: "ZZ Top", name: "La Grange", youtubeId: "oqZaDKqHFBs" },
  { artist: "Talking Heads", name: "Road to Nowhere", youtubeId: "FhRLGarxEuY" },
  { artist: "Metallica", name: "Enter Sandman", youtubeId: "Np0zgpkxAaA" },
  { artist: "070 Shake", name: "Guilty Conscience", youtubeId: "X0t8zeEiqEY" },
  { artist: "Smash Mouth", name: "I'm A Believer (From \"Shrek\" Motion Picture Soundtrack)", youtubeId: "xCP7bCJxxm8" },
  { artist: "Bob Seger & The Silver Bullet Band", name: "Hollywood Nights", youtubeId: "l85SmjhBQz0" },
  { artist: "Big Giant Circles", name: "Vindicate Me", youtubeId: "-0LjT-mMBSU" },
  { artist: "Laszlo", name: "Supernova", youtubeId: "G-gzY6P33UQ" },
  { artist: "Kendrick Lamar", name: "DNA", youtubeId: "uX6uBHPGfSs" },
  { artist: "Lady Gaga", name: "Applause", youtubeId: "4cNaX8kFsUI" },
  { artist: "Saki Kabata", name: "Lonely Rolling Star (Katamari Damacy)", youtubeId: "7_QydNXI_ok" },
  { artist: "C418", name: "Minecraft (Minecraft)", youtubeId: "XuZDeT8zI5c" },
  { artist: "Boston", name: "More Than a Feeling", youtubeId: "ufQUxoidxkM" },
  { artist: "Bon Jovi", name: "Bad Medicine", youtubeId: "AfI1QznxY5c" },
  { artist: "Todd Rundgren", name: "I Saw the Light", youtubeId: "C5zPHZ3i_FU" },
  { artist: "Cheap Trick", name: "Surrender", youtubeId: "T_Km11HNzUY" },
  { artist: "The Rolling Stones", name: "Gimme Shelter", youtubeId: "RbmS3tQJ7Os" },
  { artist: "Barbra Streisand", name: "Memory", youtubeId: "z1qartep_3I" },
  { artist: "C418", name: "Droopy Likes Ricochet (Minecraft)", youtubeId: "9jlVL4syx8k" },
  { artist: "Electric Light Orchestra", name: "Strange Magic", youtubeId: "9_PfYoDDWa0" },
  { artist: "Lou Bega", name: "Mambo No. 5 (a Little Bit of...)", youtubeId: "RUBvqz3ozv8" },
  { artist: "The Chemical Brothers", name: "Go", youtubeId: "AKZ1oa-LbjQ" },
  { artist: "No Doubt", name: "Hella Good", youtubeId: "u6Gsv7BjYPQ" },
  { artist: "Daryl Hall & John Oates", name: "I Can't Go for That (No Can Do)", youtubeId: "Ry04tCx4Bqw" },
  { artist: "Robert Palmer", name: "Simply Irresistible", youtubeId: "KCtsp0wul0A" },
  { artist: "glue70", name: "Casin", youtubeId: "fZUm1mwtk5I" },
  { artist: "TheFatRat", name: "Infinite Power", youtubeId: "twCzH75C_WE" },
  { artist: "Cheap Trick", name: "I Want You to Want Me", youtubeId: "Er9HDEq3YIY" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Black Tower (Halo 3)", youtubeId: "q_UZnGb7Cj8" },
  { artist: "Shaggy, Ricardo Ducent", name: "It Wasn't Me", youtubeId: "sTMgX1PDGAE" },
  { artist: "Van Halen", name: "Dance the Night Away", youtubeId: "E-rTKd-Alk8" },
  { artist: "Bob Seger", name: "Shakedown", youtubeId: "uS-EGbXU_Fw" },
  { artist: "Steve Winwood", name: "Higher Love", youtubeId: "PagMEUbbBcs" },
  { artist: "New Order", name: "Blue Monday", youtubeId: "iOEJHNZpeck" },
  { artist: "Heart", name: "Crazy On You", youtubeId: "EZ7inhwScSQ" },
  { artist: "Tina Charles", name: "I Love to Love", youtubeId: "RDbL8GLr1yw" },
  { artist: "John Mellencamp", name: "Small Town", youtubeId: "rXJHLlQxZqI" },
  { artist: "Foreigner", name: "Double Vision", youtubeId: "j9K68tR9IsE" },
  { artist: "The Trammps", name: "Disco Inferno (Edit)", youtubeId: "pG8TyIEAqps" },
  { artist: "Guns N' Roses", name: "Patience", youtubeId: "MkoeqtKUUe4" },
  { artist: "Pop Up!", name: "Paradise City", youtubeId: "-Sn-AJt8JCg" },
  { artist: "Galantis", name: "Peanut Butter Jelly", youtubeId: "nAXP8wb7VWI" },
  { artist: "Manfred Mann's Earth Band, Manfred Mann's Earth Band", name: "Blinded By The Light", youtubeId: "JZW9meY2IPw" },
  { artist: "Barenaked Ladies", name: "Odds Are", youtubeId: "FrMdoHrKspE" },
  { artist: "Shania Twain", name: "That Don't Impress Me Much", youtubeId: "lfAVsNSl9-g" },
  { artist: "Of Monsters and Men", name: "Mountain Sound", youtubeId: "6TmJF5jMcus" },
  { artist: "La Caution", name: "Thé à la menthe", youtubeId: "83TomNHR6cQ" },
  { artist: "Electric Light Orchestra", name: "Evil Woman", youtubeId: "nxz1DuVaRr8" },
  { artist: "Pink Floyd", name: "Hey You", youtubeId: "soL8JK6kALc" },
  { artist: "Rick Springfield", name: "Don't Talk to Strangers", youtubeId: "Tzl5k4vENss" },
  { artist: "Modjo", name: "Lady (Hear Me Tonight)", youtubeId: "MR3uP7IYz44" },
  { artist: "Hyper Potions", name: "Friends (Sonic Mania)", youtubeId: "h4JK-j_b1BQ" },
  { artist: "Madonna", name: "Vogue", youtubeId: "ydFYm-oomec" },
  { artist: "Cascada", name: "Evacuate The Dancefloor (Radio Edit)", youtubeId: "MrhgWddbVNk" },
  { artist: "Billy Squier", name: "Lonely Is The Night", youtubeId: "C5TaeDhPUQQ" },
  { artist: "Eminem, Dido", name: "Stan", youtubeId: "HIqQ0PfuPo8" },
  { artist: "Judy Collins", name: "Both Sides Now", youtubeId: "MYKi_Cj4s5s" },
  { artist: "The Cars", name: "You Might Think", youtubeId: "h9rU5gHcsAw" },
  { artist: "Foreigner", name: "Juke Box Hero", youtubeId: "UG9VTg3aHS8" },
  { artist: "Billy Joel", name: "We Didn't Start the Fire", youtubeId: "55q6jrfBDdk" },
  { artist: "The Traveling Wilburys", name: "Heading For The Light", youtubeId: "SI-il17vJ3w" },
  { artist: "Naked Eyes", name: "Promises, Promises", youtubeId: "GaGr010ey5U" },
  { artist: "Prince, Prince and The Revolution", name: "Raspberry Beret", youtubeId: "owJ8QR_qWRw" },
  { artist: "Golden Earring", name: "Twilight Zone", youtubeId: "HOuc0kuKRV4" },
  { artist: "The Police", name: "Roxanne", youtubeId: "fZheUzgIFEk" },
  { artist: "Airbourne", name: "Back In The Game", youtubeId: "FlPalDkWsuA" },
  { artist: "John Parr", name: "St. Elmos Fire (Man in Motion)", youtubeId: "or20Ovv0Mag" },
  { artist: "Matthew Wilder", name: "Break My Stride", youtubeId: "UhLDA4Wr2GU" },
  { artist: "Talk Talk", name: "It's My Life", youtubeId: "yWQezjGjiqs" },
  { artist: "Simple Minds", name: "Don't You (Forget About Me)", youtubeId: "Ly61QG9yWOc" },
  { artist: "Billy Joel", name: "My Life", youtubeId: "NlvU-EHk4Nc" },
  { artist: "Michael Jackson", name: "Smooth Criminal", youtubeId: "wkJTd_wKl0k" },
  { artist: "Vanessa Carlton", name: "A Thousand Miles", youtubeId: "PNoIn1WKiEc" },
  { artist: "Kid Rock", name: "All Summer Long", youtubeId: "FiLwPQKxOCM" },
  { artist: "Will Smith", name: "Gettin' Jiggy Wit It", youtubeId: "5VPm2yckO3k" },
  { artist: "Chicago", name: "Saturday in the Park", youtubeId: "JPOF31V-Xwg" },
  { artist: "Paul Lipson, Lennie Moore, Tom Salta, Brian Trifon, Brian Lee White", name: "Moon Over Mombasa Part 2 (Halo 2 Anniversary)", youtubeId: "rjpljY6coxo" },
  { artist: "Stevie Nicks", name: "Stop Draggin' My Heart Around (with Tom Petty and The Heartbreakers)", youtubeId: "XeyjEWIDJ5Q" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Devils... Monsters (Halo: Combat Evolved)", youtubeId: "56w4VLURmGI" },
  { artist: "The Weeknd", name: "Blinding Lights", youtubeId: "J7p4bzqLvCw" },
  { artist: "Electric Light Orchestra", name: "Hold On Tight", youtubeId: "_b4w39_MK_w" },
  { artist: "Sammy Hagar", name: "I Can't Drive 55", youtubeId: "2Qf98egLWOk" },
  { artist: "Eddie Money", name: "Two Tickets to Paradise", youtubeId: "JxcUmAVG3RQ" },
  { artist: "Talking Heads", name: "Once in a Lifetime", youtubeId: "CHatn3_UxEU" },
  { artist: "Def Leppard", name: "Hysteria", youtubeId: "FXjyoArp3ZE" },
  { artist: "Boston", name: "Don't Look Back", youtubeId: "KvmNRXdb2ng" },
  { artist: "Linkin Park", name: "CASTLE OF GLASS", youtubeId: "x2umzqh8r4g" },
  { artist: "Guns N' Roses", name: "November Rain", youtubeId: "y6lfK3bH4z8" },
  { artist: "Peter Frampton", name: "Show Me The Way", youtubeId: "Qh4zqMMaRlA" },
  { artist: "Roxette", name: "Listen To Your Heart", youtubeId: "Hs4QTkx4a9Y" },
  { artist: "Rod Stewart", name: "Baby Jane", youtubeId: "PhqULbDtVh0" },
  { artist: "Bay City Rollers", name: "Saturday Night", youtubeId: "csXFpFoRkEk" },
  { artist: "Enrique Iglesias", name: "Bailamos", youtubeId: "npzWrl7jn88" },
  { artist: "Led Zeppelin", name: "Stairway to Heaven", youtubeId: "iXQUu5Dti4g" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Reclaimer (Halo 2)", youtubeId: "yaOqNSjaca4" },
  { artist: "Matchbox Twenty", name: "If You're Gone", youtubeId: "2mCkuycJjJs" },
  { artist: "Eagles", name: "Lyin' Eyes", youtubeId: "2dANDhfWU8g" },
  { artist: "Alexandra Stan", name: "Mr. Saxobeat", youtubeId: "yFoElsPalQM" },
  { artist: "Little Big", name: "Go Bananas", youtubeId: "7ynh6LM-Mkc" },
  { artist: "Audioslave", name: "Show Me How to Live", youtubeId: "DDg2mSOmeIA" },
  { artist: "Erasure", name: "A Little Respect", youtubeId: "6aVuGV2s2qE" },
  { artist: "Bee Gees", name: "Night Fever (Serban Mix)", youtubeId: "6bpd7Gkg0MQ" },
  { artist: "Scorpions", name: "Rock You Like A Hurricane", youtubeId: "bNawbNsx52A" },
  { artist: "Steve Miller Band", name: "Fly Like An Eagle", youtubeId: "WuXwSyahgW4" },
  { artist: "Counting Crows", name: "Mr. Jones", youtubeId: "x_dtKRzbCTU" },
  { artist: "Led Zeppelin", name: "Whole Lotta Love", youtubeId: "0bcIjILqORM" },
  { artist: "Jesse Beauchamp", name: "The Final Battle (from Metagame)", youtubeId: "dXMQHti6ycU" },
  { artist: "The Bellamy Brothers", name: "Let Your Love Flow", youtubeId: "LzXz6-RXXgc" },
  { artist: "RUN DMC", name: "It's Tricky", youtubeId: "KB6SILem5Xs" },
  { artist: "Survivor", name: "The Search Is Over", youtubeId: "FBJ0f3u0oBs" },
  { artist: "Theory Of A Deadman", name: "Rx (Medicate)", youtubeId: "cpBpTio4JBs" },
  { artist: "Kenny Loggins", name: "I'm Alright (Theme from \"Caddyshack\")", youtubeId: "e2eRRkw62y0" },
  { artist: "John Mellencamp", name: "Cherry Bomb", youtubeId: "ll8YLkromVc" },
  { artist: "Los Del Rio", name: "Macarena (Bayside Boys Remix)", youtubeId: "zWaymcVmJ-A" },
  { artist: "The Black Crowes", name: "Hard To Handle", youtubeId: "h1WItDnFtTk" },
  { artist: "Maxine Nightingale", name: "Right Back Where We Started From", youtubeId: "JoI7MLxGRjQ" },
  { artist: "Thin Lizzy", name: "The Boys Are Back In Town", youtubeId: "nN120kCiVyQ" },
  { artist: "Rick Astley", name: "Never Gonna Give You Up", youtubeId: "dQw4w9WgXcQ" },
  { artist: "Depeche Mode", name: "Just Can't Get Enough", youtubeId: "yI_DKk3mxOI" },
  { artist: "Billy Joel", name: "Pressure", youtubeId: "dFR6VoUdoTo" },
  { artist: "Jeff D. Elliott", name: "Top Secret", youtubeId: "okHAnJHdLwM" },
  { artist: "Imagine Dragons", name: "Radioactive", youtubeId: "3Yb2-CWjrME" },
  { artist: "The Killers", name: "Somebody Told Me", youtubeId: "liRMxUT8A2c" },
  { artist: "Culture Club", name: "Karma Chameleon", youtubeId: "S8Cv5XbG428" },
  { artist: "Toto", name: "I Won't Hold You Back", youtubeId: "rQysrhYnWHg" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Heretic, Hero (Halo 2)", youtubeId: "0ZpyKvLB_Ec" },
  { artist: "Van Halen", name: "I'll Wait", youtubeId: "_CIE041T954" },
  { artist: "Frankie Goes To Hollywood", name: "Relax", youtubeId: "pJ2L1dh-dSo" },
  { artist: "Def Leppard", name: "Photograph", youtubeId: "hkG0P6OuTVM" },
  { artist: "Wang Chung", name: "Everybody Have Fun Tonight", youtubeId: "5c3g7a85400" },
  { artist: "Santana", name: "Black Magic Woman", youtubeId: "9wT1s96JIb0" },
  { artist: "Culture Club", name: "Do You Really Want To Hurt Me", youtubeId: "DLZtqLrzbfs" },
  { artist: "Pitbull", name: "I Know You Want Me (Calle Ocho) (Radio Edit)", youtubeId: "P7sbjPggfkU" },
  { artist: "Queen", name: "Crazy Little Thing Called Love", youtubeId: "T1qb74CtBtk" },
  { artist: "ABBA", name: "Mamma Mia", youtubeId: "KMViJKmAV4M" },
  { artist: "The Gap Band", name: "You Dropped A Bomb On Me", youtubeId: "hbh3XBwo9k4" },
  { artist: "The Who", name: "Baba O'Riley", youtubeId: "rIbMbXjbW98" },
  { artist: "U2", name: "Desire", youtubeId: "K1vFRn10Kt8" },
  { artist: "Huey Lewis & The News", name: "The Power Of Love", youtubeId: "wIiVp3poe2c" },
  { artist: "Daryl Hall & John Oates", name: "She's Gone", youtubeId: "87Q042KlxI4" },
  { artist: "Daryl Hall & John Oates", name: "Private Eyes", youtubeId: "0aQKmbtp6Pc" },
  { artist: "Robin Schulz", name: "Sugar (feat. Francesco Yates)", youtubeId: "bvC_0foemLY" },
  { artist: "Dire Straits", name: "Industrial Disease", youtubeId: "u_mF0DhDSlo" },
  { artist: "Savant", name: "Laser Sharks (Original Mix)", youtubeId: "21BgQj2sVeI" },
  { artist: "Rihanna, Calvin Harris", name: "We Found Love", youtubeId: "UUpuyzPJeWA" },
  { artist: "Nickelback", name: "How You Remind Me", youtubeId: "vkvrbbtnp6s" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Flawed Legacy (Halo 2)", youtubeId: "sVgkqB8Zdl0" },
  { artist: "Amy Grant", name: "Baby, Baby", youtubeId: "PnAD2Tb-SE8" },
  { artist: "Imagine Dragons", name: "Walking The Wire", youtubeId: "1nv9br7P7g0" },
  { artist: "Stevie Wonder", name: "Superstition", youtubeId: "ftdZ363R9kQ" },
  { artist: "Talking Heads", name: "Burning Down the House", youtubeId: "bgJ-hyzl6jg" },
  { artist: "The National", name: "Exile, Vilify (Portal 2)", youtubeId: "XUwkGFw226U" },
  { artist: "Dido", name: "Thank You", youtubeId: "1TO48Cnl66w" },
  { artist: "Jay Sean, Sean Paul, Lil Jon", name: "Do You Remember", youtubeId: "9LvgomJNXkg" },
  { artist: "Robert Palmer", name: "Addicted To Love", youtubeId: "1-BBI9cZw_U" },
  { artist: "38 Special", name: "Caught Up In You", youtubeId: "zg21Rkew874" },
  { artist: "Linkin Park", name: "Breaking the Habit", youtubeId: "_e7bqZGPyFI" },
  { artist: "Gob", name: "Oh! Ellin", youtubeId: "K59ZIVrExA0" },
  { artist: "Prince", name: "1999", youtubeId: "UWC4X_rTRsA" },
  { artist: "4 Non Blondes", name: "What's Up", youtubeId: "6NXnxTNIWkc" },
  { artist: "Rupert Holmes", name: "Escape (The Pina Colada Song)", youtubeId: "FXG_I_tf_i4" },
  { artist: "Commuter", name: "Young Hearts (The Karate Kid)", youtubeId: "ziL-jORBF_M" },
  { artist: "Mord Fustang", name: "Diamonds", youtubeId: "Ew-JkxeXvB0" },
  { artist: "LVTHER, MYZICA", name: "Some Kind of Magic (feat. MYZICA)", youtubeId: "BC9Nu2cG5J4" },
  { artist: "Ricky Martin", name: "Livin' la Vida Loca", youtubeId: "98B9ZHOlk2E" },
  { artist: "Michael Jackson", name: "Wanna Be Startin' Somethin'", youtubeId: "8KWf_-ofYgI" },
  { artist: "Prince & The Revolution", name: "Let's Go Crazy", youtubeId: "eCM2dEWGf-o" },
  { artist: "Daryl Hall & John Oates", name: "Out of Touch", youtubeId: "DCkJ5lGPqFs" },
  { artist: "Blue Oyster Cult", name: "(Don't Fear) The Reaper", youtubeId: "Dy4HA3vUv2c" },
  { artist: "The Human League", name: "Don't You Want Me", youtubeId: "UscEO2ITyug" },
  { artist: "Isaac Hayes", name: "Walk On By", youtubeId: "5loAY27W5IY" },
  { artist: "Falco", name: "Rock Me Amadeus", youtubeId: "OKxBk4V79Co" },
  { artist: "Grand Funk Railroad", name: "Some Kind Of Wonderful", youtubeId: "NiaVYUdfAow" },
  { artist: "Big Giant Circles", name: "Go for Distance", youtubeId: "VGg05WGHThQ" },
  { artist: "Duck Sauce", name: "Barbra Streisand (Original Mix)", youtubeId: "nTx_1rli-Lw" },
  { artist: "Genesis", name: "Abacab", youtubeId: "rCsPjYcTQ_M" },
  { artist: "Jaytech", name: "Pepe's Garden (Original Mix)", youtubeId: "l8nt2eCvsrg" },
  { artist: "Billy Joel", name: "It's Still Rock and Roll to Me", youtubeId: "lvlPoBduEe4" },
  { artist: "Meat Loaf", name: "Bat Out of Hell", youtubeId: "x7ES7ueI7p0" },
  { artist: "The Romantics", name: "Talking in Your Sleep", youtubeId: "YpZgagU50KE" },
  { artist: "The Black Keys", name: "Fever", youtubeId: "tk5m_TAD_AM" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Three Gates (Halo 3)", youtubeId: "d0zr33BwWMg" },
  { artist: "Toby Fox", name: "Death by Glamour (Undertale)", youtubeId: "2TgO-tN5wAM" },
  { artist: "Buster Poindexter and His Banshees Of Blue", name: "Hot Hot Hot", youtubeId: "bKP18BpvX_o" },
  { artist: "Michael Jackson", name: "Rock with You", youtubeId: "OvzJZTkWYoY" },
  { artist: "Grand Funk Railroad", name: "We're An American Band", youtubeId: "QxNXFNCTr2I" },
  { artist: "Paul Simon", name: "You Can Call Me Al", youtubeId: "OMbfT3Wppjo" },
  { artist: "Hot Chelle Rae, New Boyz", name: "I Like It Like That", youtubeId: "Xvs5YJ7-two" },
  { artist: "The Alan Parsons Project", name: "Eye In The Sky", youtubeId: "jEILGYq7eso" },
  { artist: "Phil Collins", name: "Strangers Like Me", youtubeId: "G02h2DJ3GCs" },
  { artist: "Wesley Willis", name: "Retard Bus", youtubeId: "FOcuEbUPyGU" },
  { artist: "Joe \"Bean\" Esposito", name: "You're The Best (The Karate Kid)", youtubeId: "p44G0U4sLCE" },
  { artist: "Heart", name: "Barracuda", youtubeId: "p6v6wWmEYvY" },
  { artist: "Breaking Benjamin", name: "Blow Me Away (Halo 2)", youtubeId: "tj9QbiBaSsk" },
  { artist: "Fleetwood Mac", name: "Dreams", youtubeId: "swJOIjjW69U" },
  { artist: "The Ozark Mountain Daredevils", name: "Jackie Blue", youtubeId: "6xuW-aI-haw" },
  { artist: "Matchbox Twenty", name: "She's so Mean", youtubeId: "KCdPYV9bLPI" },
  { artist: "Bee Gees", name: "You Should Be Dancing", youtubeId: "4Qhe7aXeY4g" },
  { artist: "Depeche Mode", name: "People Are People", youtubeId: "dY1vKArUrtA" },
  { artist: "Heart", name: "Tell It Like It Is", youtubeId: "lYWoj71iO_A" },
  { artist: "King Missile", name: "Cheesecake Truck", youtubeId: "R1QfjHZ2isU" },
  { artist: "Santana, Rob Thomas", name: "Smooth", youtubeId: "mom2TLs3Fi0" },
  { artist: "Daryl Hall & John Oates", name: "You Make My Dreams (Come True)", youtubeId: "E4CZuj7solw" },
  { artist: "Papa Roach", name: "Last Resort", youtubeId: "j2R6uT7uLFg" },
  { artist: "Foghat", name: "Slow Ride", youtubeId: "T4287tw_dwk" },
  { artist: "Taco, Irving Berlin, Irving Berlin", name: "Puttin' on the Ritz", youtubeId: "TMstTM01m28" },
  { artist: "Bon Jovi", name: "Livin' On A Prayer", youtubeId: "YBdyc1WDlBQ" },
  { artist: "Lee Ann Womack", name: "I Hope You Dance", youtubeId: "ozR3XL4cab0" },
  { artist: "The Cars", name: "Magic", youtubeId: "qB_2B5tVbtk" },
  { artist: "Billy Joel", name: "Movin' Out (Anthony's Song)", youtubeId: "QrR-7QFquok" },
  { artist: "Jim Guthrie", name: "Dark Flute", youtubeId: "6L5lJ-WRYaA" },
  { artist: "Steely Dan", name: "Do It Again", youtubeId: "yCgHTmv4YU8" },
  { artist: "Thomas Dolby", name: "She Blinded Me With Science", youtubeId: "wdSUnV2fJGk" },
  { artist: "Sixpence None The Richer", name: "Kiss Me", youtubeId: "K2tbQ_g2VbQ" },
  { artist: "Nitro Fun, Hyper Potions", name: "Checkpoint (Sonic Mania)", youtubeId: "0G2ZAq1CZVg" },
  { artist: "Katy Perry", name: "E.T.", youtubeId: "We1Oe59I9Os" },
  { artist: "fox capture plan", name: "Butterfly Effect", youtubeId: "b2y8BY9v4u4" },
  { artist: "Gorillaz", name: "DARE", youtubeId: "SZP0NFRKBbs" },
  { artist: "Kool & The Gang", name: "Get Down On It", youtubeId: "1AVxBedMP4I" },
  { artist: "Fatboy Slim", name: "Weapon Of Choice", youtubeId: "TsRvbnQVI6I" },
  { artist: "The Alan Parsons Project", name: "Games People Play", youtubeId: "GHyXUAKbq1M" },
  { artist: "Madonna", name: "Into the Groove (Edit)", youtubeId: "1zh-0eupfNw" },
  { artist: "KC & The Sunshine Band", name: "Get Down Tonight", youtubeId: "tUMk0RbZDZY" },
  { artist: "Guns N' Roses", name: "Live And Let Die", youtubeId: "RywqQ_AKB9M" },
  { artist: "Toby Fox", name: "Field of Hopes and Dreams (Deltarune)", youtubeId: "ymNgfwgh1TU" },
  { artist: "The Doobie Brothers", name: "Long Train Runnin'", youtubeId: "Cbyf_3NjUW8" },
  { artist: "Goo Goo Dolls", name: "Black Balloon", youtubeId: "dH7pJy-VcTA" },
  { artist: "War", name: "Low Rider", youtubeId: "qMkwuz0iXQg" },
  { artist: "Eagles", name: "Take It Easy", youtubeId: "32Oc2d_3yEk" },
  { artist: "Don McLean", name: "American Pie", youtubeId: "iX_TFkut1PM" },
  { artist: "MACINTOSH PLUS", name: "リサフランク420 / 現代のコンピュー", youtubeId: "aQkPcPqTq4M" },
  { artist: "Tom Petty", name: "Love Is A Long Road", youtubeId: "2AilA-M6N5U" },
  { artist: "Tay Zonday", name: "Chocolate Rain", youtubeId: "k7vJPCkOmx8" },
  { artist: "Van Halen", name: "Panama", youtubeId: "YRFKU6QvBJY" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Behold a Pale Horse (Halo 3)", youtubeId: "zJUuMk18sqQ" },
  { artist: "Alphaville", name: "Big in Japan", youtubeId: "WlKhxulA4O8" },
  { artist: "Green Day", name: "Boulevard of Broken Dreams", youtubeId: "gYCTXzOTnXg" },
  { artist: "Van Halen", name: "Ain't Talkin' 'Bout Love", youtubeId: "qtwBFz6lfrY" },
  { artist: "Dave Rodgers", name: "Beat of the Rising Sun", youtubeId: "5WyN2y2FPnQ" },
  { artist: "LMFAO, Lauren Bennett, GoonRock", name: "Party Rock Anthem", youtubeId: "wO2TLZ6Pqo4" },
  { artist: "Bryan Adams", name: "(Everything I Do) I Do It For You", youtubeId: "Y0pdQU87dc8" },
  { artist: "Scandal, Patty Smyth", name: "Goodbye to You", youtubeId: "pkt7bvOjz_I" },
  { artist: "Dr. John", name: "Right Place Wrong Time", youtubeId: "W4PjWgiH-LQ" },
  { artist: "Mötley Crüe", name: "Girls, Girls, Girls", youtubeId: "XZU5XyqnuyM" },
  { artist: "Led Zeppelin", name: "Kashmir", youtubeId: "SsaFBNvhceA" },
  { artist: "Prince", name: "Little Red Corvette", youtubeId: "OUZyb7YeEYA" },
  { artist: "Jordin Sparks", name: "S.O.S. (Let the Music Play)", youtubeId: "ecme6iALZzE" },
  { artist: "Imagine Dragons", name: "Round And Round", youtubeId: "TsIERBf9cuE" },
  { artist: "Darude, Jaakko Salovaara, Ville Virtanen", name: "Sandstorm", youtubeId: "hQ5ufAE3d7w" },
  { artist: "Cream", name: "Sunshine Of Your Love", youtubeId: "f3y8jf01UY8" },
  { artist: "Stevie Wonder", name: "As", youtubeId: "eLbmdG8U60E" },
  { artist: "Commodores", name: "Brick House", youtubeId: "gn6b9coJz3s" },
  { artist: "Yaz", name: "Only You", youtubeId: "NvSzjPee89w" },
  { artist: "Daughtry", name: "No Surprise", youtubeId: "ZBYJDzRyQxE" },
  { artist: "The Script, will.i.am", name: "Hall of Fame", youtubeId: "3Nn4JqTNB9g" },
  { artist: "Daughtry, Slash", name: "What I Want", youtubeId: "7ems67L-zNY" },
  { artist: "Kenji Niinuma", name: "The Moon and the Prince (Katamari Damacy)", youtubeId: "pUJeiEva0Hw" },
  { artist: "38 Special", name: "Hold On Loosely", youtubeId: "g3nn6WfFQ7o" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Unyielding (Halo 2)", youtubeId: "xYuxrYdL-D0" },
  { artist: "Rob Thomas", name: "Little Wonders", youtubeId: "GpSYDmYZgkA" },
  { artist: "Pat Benatar", name: "Hit Me With Your Best Shot", youtubeId: "rXs9MXrHxVE" },
  { artist: "Harry Chapin", name: "Cat's in the Cradle", youtubeId: "jmhoOp2fUzg" },
  { artist: "Petula Clark", name: "Downtown", youtubeId: "ZYfRm2HbWxw" },
  { artist: "Imagine Dragons", name: "Leave Me", youtubeId: "ogsxHQEorIc" },
  { artist: "The Script", name: "For the First Time", youtubeId: "u1lD5gDw6Lo" },
  { artist: "Daft Punk", name: "One More Time", youtubeId: "fa5IWHDbftI" },
  { artist: "Black Sabbath", name: "Iron Man", youtubeId: "BwoHuf3eJQg" },
  { artist: "Elton John", name: "Rocket Man (I Think It's Going To Be A Long, Long Time)", youtubeId: "BdEe5SpdIuo" },
  { artist: "Saint Motel", name: "My Type", youtubeId: "32faUlvDxCw" },
  { artist: "The Black Keys", name: "Little Black Submarines", youtubeId: "DhKAh4RJM0Q" },
  { artist: "The Darkness", name: "I Believe in a Thing Called Love", youtubeId: "E1i34e0sDt8" },
  { artist: "M.O.O.N.", name: "Paris (Hotline Miami)", youtubeId: "8wXRvvGr8Ug" },
  { artist: "Fleetwood Mac", name: "Everywhere", youtubeId: "wLSUDSNqLgQ" },
  { artist: "Approaching Nirvana", name: "Sugar High", youtubeId: "ngsGBSCDwcI" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Truth and Reconciliation Suite (Halo: Combat Evolved)", youtubeId: "lj91a6W91J8" },
  { artist: "Black Sabbath", name: "Paranoid", youtubeId: "v24ljjtqb-U" },
  { artist: "U2", name: "Pride (In The Name Of Love)", youtubeId: "nIPJ0TPf3sQ" },
  { artist: "Savage Garden", name: "Truly Madly Deeply", youtubeId: "5lGqXIiDxFM" },
  { artist: "Phil Collins", name: "In The Air Tonight", youtubeId: "y7f0x_UFfJE" },
  { artist: "Simon & Garfunkel", name: "The Sound of Silence (Electric Version)", youtubeId: "bjkPQYWNTlg" },
  { artist: "The Fratellis", name: "Chelsea Dagger", youtubeId: "2gDb_axpfeY" },
  { artist: "Boston", name: "Feelin' Satisfied", youtubeId: "5Gh-gQY1dus" },
  { artist: "Tove Lo", name: "Talking Body", youtubeId: "HfZ6rflYapM" },
  { artist: "Survivor", name: "Burning Heart (From \"Rocky IV\" Soundtrack)", youtubeId: "Nm7AA78Vq8o" },
  { artist: "Eminem, Nate Dogg", name: "Till I Collapse", youtubeId: "CO4kW70AchE" },
  { artist: "Seal", name: "Crazy", youtubeId: "L0NoJi8omqc" },
  { artist: "Tom Petty And The Heartbreakers", name: "I Need to Know", youtubeId: "SkYhfDr77jc" },
  { artist: "Boney M.", name: "Daddy Cool", youtubeId: "iQEpTa3VqLU" },
  { artist: "Fleetwood Mac", name: "You Make Loving Fun", youtubeId: "RqjXn2NflqU" },
  { artist: "Franz Ferdinand", name: "Take Me Out", youtubeId: "d11xns8lE4w" },
  { artist: "Paula Abdul", name: "Straight Up", youtubeId: "V26f420X9r4" },
  { artist: "EHAMIC, Frédéric Chopin", name: "Koinu no Carnival (from \"Minute Waltz\")", youtubeId: "ZhZxKhAilZk" },
  { artist: "Empire Of The Sun", name: "We Are The People", youtubeId: "r02mYOlfcCw" },
  { artist: "Ben Folds", name: "Rockin' the Suburbs", youtubeId: "nD30KV26ivg" },
  { artist: "Loverboy", name: "Working for the Weekend", youtubeId: "wajEoz-Sm8U" },
  { artist: "Sly & The Family Stone", name: "Everyday People", youtubeId: "TU02G4wnWh0" },
  { artist: "Asia", name: "Heat Of The Moment", youtubeId: "nvdYll1euGU" },
  { artist: "The Cult", name: "She Sells Sanctuary", youtubeId: "cQS4TzB0EvU" },
  { artist: "OK Go", name: "Needing/Getting", youtubeId: "MejbOFk7H6c" },
  { artist: "Michael Jackson", name: "Beat It", youtubeId: "kOn-HdEg6AQ" },
  { artist: "The Rolling Stones", name: "Miss You", youtubeId: "PKVXSo9ROpg" },
  { artist: "Pink Floyd", name: "Run Like Hell", youtubeId: "j2s8yGMEbSs" },
  { artist: "Fleetwood Mac", name: "The Chain", youtubeId: "FhgFtXESdPk" },
  { artist: "Christopher Cross", name: "Arthur's Theme (Best That You Can Do)", youtubeId: "WOBHXxiZyZM" },
  { artist: "Real McCoy", name: "Another Night", youtubeId: "OqYG87oxlGo" },
  { artist: "Guns N' Roses", name: "Sweet Child O' Mine", youtubeId: "oMfMUfgjiLg" },
  { artist: "Tom Petty And The Heartbreakers", name: "You Got Lucky", youtubeId: "ZzN1pM_5-Eo" },
  { artist: "Foreigner", name: "Cold as Ice", youtubeId: "SdOFadTTV_8" },
  { artist: "Atlanta Rhythm Section", name: "Imaginary Lover", youtubeId: "nVQFHy6mM5A" },
  { artist: "Otis McDonald", name: "Not For Nothing", youtubeId: "mGpXOasfFkE" },
  { artist: "Jack Johnson", name: "Upside Down", youtubeId: "e2vLWbaOITw" },
  { artist: "Five for Fighting", name: "Superman (It's Not Easy)", youtubeId: "GRz4FY0ZcwI" },
  { artist: "Oasis, Noel Gallagher", name: "Stop Crying Your Heart Out", youtubeId: "q-jkObVL-P8" },
  { artist: "Queen", name: "Killer Queen", youtubeId: "nUY5eNwu8S8" },
  { artist: "Will Smith", name: "Men In Black (From \"Men In Black\" Soundtrack)", youtubeId: "fzMXRGroHGk" },
  { artist: "The Allman Brothers Band", name: "Jessica", youtubeId: "vTOozRAJ8dU" },
  { artist: "Lady Gaga, Space Cowboy, Flo Rida", name: "Starstruck", youtubeId: "n6LBhLPCbc4" },
  { artist: "deadmau5 feat. Rob Swire", name: "Monophobia", youtubeId: "NESCd6IFfLM" },
  { artist: "Pat Benatar", name: "We Belong", youtubeId: "CV6n5QBnne8" },
  { artist: "Toto", name: "Rosanna", youtubeId: "SGtVZgCYVgk" },
  { artist: "Zombie Nation", name: "Kernkraft 400", youtubeId: "gE2Cs7Ks2Zg" },
  { artist: "311", name: "Stealing Happy Hours", youtubeId: "P22-e4Ol49s" },
  { artist: "HOME", name: "Head First", youtubeId: "j4Jyev7iTlE" },
  { artist: "KC & The Sunshine Band", name: "That's the Way (I Like It)", youtubeId: "aXxB2QN2IuY" },
  { artist: "Tom Petty And The Heartbreakers", name: "Listen To Her Heart", youtubeId: "Amow_25-5Wc" },
  { artist: "Fitz and The Tantrums", name: "The Walker", youtubeId: "A_300cnCdYI" },
  { artist: "U2", name: "Vertigo", youtubeId: "8TXnjGtIhyc" },
  { artist: "Free", name: "All Right Now", youtubeId: "qBj5zJjHoSk" },
  { artist: "The Bangles", name: "Walk Like an Egyptian", youtubeId: "cFGCu5hnLyA" },
  { artist: "Cypis", name: "Gdzie jest biały węgorz ? (Zejście)", youtubeId: "nVxGcpBdk5k" },
  { artist: "Madonna", name: "Material Girl", youtubeId: "UZton86SuOg" },
  { artist: "Oasis, Noel Gallagher", name: "Don't Look Back In Anger", youtubeId: "oplra1FJxWI" },
  { artist: "The Rembrandts", name: "I'll Be There for You (Theme From \"Friends\")", youtubeId: "RIjTq_OdFvo" },
  { artist: "Eric Johnson", name: "Cliffs Of Dover (Instrumental)", youtubeId: "wpAC1vr_pcg" },
  { artist: "Michael Jackson", name: "Don't Stop 'Til You Get Enough", youtubeId: "n3qQtSRmHxo" },
  { artist: "The Chain Gang Of 1974", name: "Sleepwalking", youtubeId: "C8fwJQeCylI" },
  { artist: "Vertical Horizon", name: "Everything You Want", youtubeId: "FwTQOPZvY3s" },
  { artist: "Kenny Loggins, Steve Perry", name: "Don't Fight It", youtubeId: "izWz_qoiKVs" },
  { artist: "Supertramp", name: "The Logical Song", youtubeId: "pP8iUyb9Gn8" },
  { artist: "Madonna", name: "Papa Don't Preach", youtubeId: "spsbYZ3y83E" },
  { artist: "Justice", name: "DVNO", youtubeId: "eLOtl3nLR7Q" },
  { artist: "C418", name: "Subwoofer Lullaby (Minecraft)", youtubeId: "ibUOxEBxVsE" },
  { artist: "FM Attack", name: "A Million Miles Away", youtubeId: "pdZsv8CNl1w" },
  { artist: "Styx", name: "Don't Let It End", youtubeId: "oKUOy-Tam70" },
  { artist: "Righeira", name: "No Tengo Dinero (1983 Maxi)", youtubeId: "2fXWJX3tTXI" },
  { artist: "Kansas", name: "Dust in the Wind", youtubeId: "zVokojoF_lY" },
  { artist: "Billy Idol", name: "Rebel Yell", youtubeId: "seHlHzYpWBU" },
  { artist: "The Animals", name: "Don't Let Me Be Misunderstood", youtubeId: "YMDrTMnm1IE" },
  { artist: "America", name: "A Horse with No Name", youtubeId: "mSZXWdKSQNM" },
  { artist: "Rush", name: "YYZ", youtubeId: "ScaPlYMgwTU" },
  { artist: "Oasis, Noel Gallagher", name: "Wonderwall", youtubeId: "hpSrLjc5SMs" },
  { artist: "Ratt", name: "Round and Round", youtubeId: "5jZ9IoMqHcY" },
  { artist: "Michelle Branch", name: "Breathe", youtubeId: "DgRhOuOibV4" },
  { artist: "Bachman-Turner Overdrive", name: "You Ain't Seen Nothing Yet", youtubeId: "VJsMVSFzRSc" },
  { artist: "Red Hot Chili Peppers", name: "Give It Away", youtubeId: "XhjqmAoBKCQ" },
  { artist: "The Clash", name: "Rock the Casbah", youtubeId: "0pCFVX6lzHU" },
  { artist: "Flo Rida, T-Pain", name: "Low (feat. T-Pain)", youtubeId: "uUL8a7eJCk8" },
  { artist: "Bon Jovi", name: "Have A Nice Day", youtubeId: "_GorjQ1K8-U" },
  { artist: "TheFatRat", name: "Unity", youtubeId: "P7nF7jXF-Jg" },
  { artist: "Supertramp", name: "Give A Little Bit", youtubeId: "0dJ30dREjaE" },
  { artist: "Rob Thomas", name: "This Is How a Heart Breaks", youtubeId: "th9FF3Gefvs" },
  { artist: "Kiss", name: "Shout It Out Loud", youtubeId: "1rJhk-ZceOo" },
  { artist: "Katy Perry", name: "Hot N Cold", youtubeId: "C-jAPrdc3PI" },
  { artist: "Genesis", name: "Invisible Touch", youtubeId: "rlD0XQibpaY" },
  { artist: "Bon Jovi", name: "You Give Love A Bad Name", youtubeId: "Aqc3VTpz9HQ" },
  { artist: "The Chemical Brothers", name: "Star Guitar", youtubeId: "0S43IwBF0uM" },
  { artist: "ZZ Top", name: "Sharp Dressed Man", youtubeId: "WUnp0xPF6zw" },
  { artist: "twenty one pilots", name: "Hometown", youtubeId: "wS8otCVxpkw" },
  { artist: "The Avalanches", name: "Because I’m Me", youtubeId: "NynnApj2smY" },
  { artist: "Joey Scarbury", name: "Believe It or Not (Theme from \"Greatest American Hero\")", youtubeId: "WqF5M7IK7I0" },
  { artist: "Elton John", name: "Bennie And The Jets", youtubeId: "ZBUW433Porw" },
  { artist: "Goo Goo Dolls", name: "Iris", youtubeId: "Dy_eP-mqWow" },
  { artist: "VERKA SERDUCHKA, Андрей Данилко, DR", name: "Dancing Lasha Tumbai", youtubeId: "IBc7c5sWRbQ" },
  { artist: "Rooney", name: "When Did Your Heart Go Missing?", youtubeId: "VypvZSfqIzM" },
  { artist: "Frank Sinatra", name: "My Way", youtubeId: "qQzdAsjWGPg" },
  { artist: "Eddie Money", name: "Think I'm In Love", youtubeId: "R3olMu5qxlw" },
  { artist: "Eurythmics, Annie Lennox, Dave Stewart", name: "Thorn In My Side", youtubeId: "-kDlEZb_7so" },
  { artist: "Derek & The Dominos", name: "Layla", youtubeId: "TngViNw2pOo" },
  { artist: "Billy Joel", name: "Say Goodbye to Hollywood", youtubeId: "SrFy957l8ko" },
  { artist: "Prince", name: "When Doves Cry", youtubeId: "oTUSeac7IuQ" },
  { artist: "Yes", name: "Leave It (Single Remix)", youtubeId: "tSgz2B-Nn5Y" },
  { artist: "Aretha Franklin", name: "Respect", youtubeId: "A134hShx_gw" },
  { artist: "Above & Beyond", name: "Giving It Out (feat. Zoë Johnston)", youtubeId: "5hJE3JCexDM" },
  { artist: "Kenny Loggins", name: "I'm Free (Heaven Helps The Man) (from Footloose)", youtubeId: "SOGKLMKg8Lw" },
  { artist: "Michael Jackson", name: "Bad", youtubeId: "-DlMoJ2V6uk" },
  { artist: "Aerosmith", name: "Dude Looks Like A Lady", youtubeId: "acQ6jLe3R8E" },
  { artist: "Todd Rundgren", name: "Bang the Drum All Day", youtubeId: "gO17hN-YvBc" },
  { artist: "Jordin Sparks", name: "One Step At a Time", youtubeId: "1WDmCPTlC3k" },
  { artist: "TLC", name: "No Scrubs", youtubeId: "q3MkrF5fPew" },
  { artist: "Tom Petty And The Heartbreakers", name: "Mary Jane's Last Dance", youtubeId: "M8XaGxvuFOU" },
  { artist: "Will Smith, Dru Hill, Kool Mo Dee", name: "Wild Wild West", youtubeId: "vRLED8AvVvQ" },
  { artist: "Kiss", name: "Detroit Rock City (Edit)", youtubeId: "jUIAf-2Hre4" },
  { artist: "The Alan Parsons Project", name: "Psychobabble", youtubeId: "G7uIfjCph9A" },
  { artist: "David Bowie", name: "Heroes", youtubeId: "YLp2cW7ICCU" },
  { artist: "Creedence Clearwater Revival", name: "Bad Moon Rising", youtubeId: "SuRCxakQt-c" },
  { artist: "Billy Joel", name: "The Entertainer", youtubeId: "MuE_D9tlQ-c" },
  { artist: "Toto", name: "Hold the Line", youtubeId: "ZP69PLBqFUo" },
  { artist: "Måns Zelmerlöw", name: "Heroes", youtubeId: "oqMi3IjaqqE" },
  { artist: "Martika", name: "Toy Soldiers", youtubeId: "b3v6dVDXtp0" },
  { artist: "Looking Glass", name: "Brandy (You're a Fine Girl)", youtubeId: "CFhFyvk0yS8" },
  { artist: "Eminem", name: "Without Me", youtubeId: "9SbT3q1a6dQ" },
  { artist: "Buffalo Springfield", name: "For What It's Worth", youtubeId: "1eD-8NTwP9I" },
  { artist: "AJR", name: "Burn The House Down", youtubeId: "tsODB-N84WY" },
  { artist: "Crowded House", name: "Don't Dream It's Over", youtubeId: "H7UMRkp7m80" },
  { artist: "10cc", name: "Honeymoon With B Troop", youtubeId: "xTlgQCrd7bk" },
  { artist: "Piano Fantasia, Serge Bijaoui, Thierry Durbet, Mike Serbee, Tidi", name: "Song for Denise (Maxi Version)", youtubeId: "hH9M-m3WD0g" },
  { artist: "Third Eye Blind", name: "Never Let You Go", youtubeId: "2nroaoWEXIA" },
  { artist: "Toto", name: "Stop Loving You", youtubeId: "qTXZ91Ek_NA" },
  { artist: "Warren G, Nate Dogg", name: "Regulate", youtubeId: "a02YJhCeTQk" },
  { artist: "REO Speedwagon", name: "Roll with the Changes", youtubeId: "m1ml05Ty5Ao" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "Asphalt, Ablution (Halo 3: ODST)", youtubeId: "luzfzTy_bso" },
  { artist: "Tears For Fears", name: "Mad World", youtubeId: "vlRAstW6cCQ" },
  { artist: "Toby Fox", name: "Dummy! (Undertale)", youtubeId: "94JDIBZhSBM" },
  { artist: "Van Halen", name: "Jump", youtubeId: "bX9RMdcFQAw" },
  { artist: "Golden Earring", name: "Radar Love", youtubeId: "GyAvXV1NJNY" },
  { artist: "Pet Shop Boys", name: "West End Girls", youtubeId: "-G8KmNF-xc4" },
  { artist: "John Mellencamp", name: "Pink Houses", youtubeId: "2uCQzHtGt6Q" },
  { artist: "Lynyrd Skynyrd", name: "Free Bird", youtubeId: "CqnU_sJ8V-E" },
  { artist: "Creedence Clearwater Revival", name: "Born On The Bayou", youtubeId: "Exu8w2sp2Gg" },
  { artist: "Savant", name: "Splinter (Original Mix)", youtubeId: "TdBGb6vB9nU" },
  { artist: "Duran Duran", name: "Ordinary World", youtubeId: "vm5iK_tNWAg" },
  { artist: "Shinedown", name: "Second Chance", youtubeId: "kOFVsn6U9L8" },
  { artist: "Van Halen", name: "Why Can't This Be Love", youtubeId: "ybbfEzKfpts" },
  { artist: "Goo Goo Dolls", name: "Name", youtubeId: "sQxszQ9BsxM" },
  { artist: "Marty Robbins", name: "Big Iron", youtubeId: "-NuX79Ud8zI" },
  { artist: "Eric Carmen", name: "All by Myself", youtubeId: "iN9CjAfo5n0" },
  { artist: "Blue Oyster Cult", name: "Burnin' for You", youtubeId: "_c8GSP4kkio" },
  { artist: "R.E.M.", name: "Losing My Religion", youtubeId: "Efa6BAWPm9o" },
  { artist: "Jackie Wilson", name: "[Your Love Keeps Lifting Me] Higher & Higher", youtubeId: "Gn6SNbTD0_Q" },
  { artist: "REO Speedwagon", name: "One Lonely Night", youtubeId: "k2y6NC5mDWg" },
  { artist: "Lindsey Stirling", name: "Electric Daisy Violin", youtubeId: "XRSIZbqKs-A" },
  { artist: "Billy Squier", name: "Rock Me Tonite", youtubeId: "PhnEpg9VMY8" },
  { artist: "Vanilla Ice", name: "Ice Ice Baby", youtubeId: "jewmMjnbfzE" },
  { artist: "Journey", name: "Girl Can't Help It", youtubeId: "qP8yqgLMBYw" },
  { artist: "Duran Duran", name: "The Reflex", youtubeId: "xl_mWGyEUm8" },
  { artist: "Stardust, Benjamin Diamond, Alan Braxe", name: "Music Sounds Better With You", youtubeId: "Hf244LCkkLc" },
  { artist: "The Outfield", name: "Your Love", youtubeId: "W2a8Ng1qM8Q" },
  { artist: "Eagles", name: "The Long Run", youtubeId: "skC2GToR1uY" },
  { artist: "Tal Bachman", name: "She's so High", youtubeId: "jmB9-99_9Y8" },
  { artist: "No Doubt", name: "Spiderwebs", youtubeId: "UBVEie8bNdE" },
  { artist: "Bee Gees", name: "Tragedy", youtubeId: "qzNDY1VknaQ" },
  { artist: "Martin O’Donnell, Michael Salvatori", name: "On a Pale Horse (Halo: Combat Evolved)", youtubeId: "cvaEIiboA8w" },
  { artist: "Mega Man X", name: "Storm Eagle (Sega Genesis Remix)", youtubeId: "F28uArEvtxI" },
  { artist: "The Police", name: "Every Little Thing She Does Is Magic", youtubeId: "5WEjz2YoH80" },
  { artist: "Pegboard Nerds", name: "Try This (Just Shapes & Beats)", youtubeId: "GIK5VWI_iqM" },
  { artist: "Jet", name: "Are You Gonna Be My Girl", youtubeId: "54dLepp2b0A" },
  { artist: "Def Leppard", name: "Pour Some Sugar On Me", youtubeId: "Rqs4cMyMLnY" },
  { artist: "R.E.M.", name: "The One I Love", youtubeId: "pBHBuFqy6I8" },
  { artist: "AC/DC", name: "Thunderstruck", youtubeId: "v2AC41dglnM" },
  { artist: "C418", name: "Stal (Minecraft)", youtubeId: "PRUtpWzsSEg" },
  { artist: "twenty one pilots", name: "Levitate", youtubeId: "YHoGXDYGkIw" },
  { artist: "O-Zone", name: "Dragostea din tei", youtubeId: "f7P5Q2IIT4U" },
  { artist: "AC/DC", name: "Highway to Hell", youtubeId: "l482T0yNkeo" },
  { artist: "Boney M.", name: "Ma Baker", youtubeId: "2GogVZiVFxM" },
  { artist: "The Clash", name: "Should I Stay or Should I Go", youtubeId: "wjZMcWaniA4" },
  { artist: "Coldplay", name: "Clocks", youtubeId: "PFW2uSCZ0uE" },
  { artist: "HOME", name: "Dream Head", youtubeId: "6ojLO9GGbIk" },
  { artist: "Michael Jackson", name: "Man in the Mirror", youtubeId: "8gqMRlN6mAA" },
  { artist: "Styx", name: "Show Me The Way", youtubeId: "lM0oIlMSFSg" },
  { artist: "20syl", name: "Ongoing Thing (feat. Oddisee)", youtubeId: "zKoOIsVGPpo" },
  { artist: "ZZ Top", name: "Legs", youtubeId: "2mrt8vyHksU" },
  { artist: "Imagine Dragons", name: "Hear Me", youtubeId: "VDiPFn8NVkE" },
  { artist: "Skid Row", name: "18 and Life", youtubeId: "wwQWJnVREZ0" },
  { artist: "Niko", name: "Night of Fire (Extended mix)", youtubeId: "7BTWpImCDq4" },
  { artist: "Imagine Dragons", name: "My Fault", youtubeId: "Px5QYwr1X5k" },
  { artist: "Journey", name: "Separate Ways (Worlds Apart)", youtubeId: "haCOU64qCo4" },
  { artist: "The Police", name: "Message In A Bottle", youtubeId: "WMl1xKJeuuQ" },
  { artist: "Donna Summer", name: "MacArthur Park", youtubeId: "Rkt8mtIkYI4" },
  { artist: "Electric Light Orchestra", name: "Shine a Little Love", youtubeId: "kZd2qCxgPEM" },
  { artist: "Gerry Rafferty", name: "Right Down the Line", youtubeId: "lnc2ljZA3bk" },
  { artist: "Sheryl Crow", name: "All I Wanna Do", youtubeId: "RiOtjXUpflY" },
  { artist: "Shania Twain", name: "Honey, I'm Home", youtubeId: "a-P-bcMbK1I" },
  { artist: "Queen", name: "Bohemian Rhapsody", youtubeId: "utwMHfDZ6SA" },
  { artist: "Nickelback", name: "Rockstar", youtubeId: "OiQEfJFMzDw" },
  { artist: "Can't Stop Won't Stop", name: "Cool Summer", youtubeId: "0NeVgwY0wJc" },
  { artist: "Maroon 5", name: "This Love", youtubeId: "AqQuRw0S6b4" },
  { artist: "Foo Fighters", name: "Best of You", youtubeId: "L6SIgRlijmU" },
  { artist: "OK Go", name: "Do What You Want", youtubeId: "zUTQ6b8Uq6g" },
  { artist: "R.E.M.", name: "Shiny Happy People", youtubeId: "nsI2BdCmoCA" },
  { artist: "AC/DC", name: "T.N.T.", youtubeId: "NhsK5WExrnE" },
  { artist: "Wesley Willis", name: "I Wupped Batman's Ass", youtubeId: "k8gHubY94rA" },
  { artist: "IceJJFish", name: "On The Floor (Official Music Video) ThatRaw.com Presents", youtubeId: "iq_d8VSM0nw" },
  { artist: "Journey", name: "Only The Young", youtubeId: "AzWGWQ0jUY0" },
  { artist: "Kaskade", name: "Move For me", youtubeId: "FwKSZrF6aXE" },
  { artist: "Bill Wurtz", name: "Outside", youtubeId: "p0omtdahVJE" },
  { artist: "Jars Of Clay", name: "Flood", youtubeId: "Dhrzoq3WbV4" },
  { artist: "Mountain", name: "Mississippi Queen", youtubeId: "VbP4qf8PjfI" },
  { artist: "The Fixx", name: "One Thing Leads To Another", youtubeId: "-Cm8XfpnOVY" },
  { artist: "Michael Sembello", name: "Maniac", youtubeId: "6GCNUeTFSbA" },
  { artist: "Sam & Dave", name: "Hold On, I'm Coming", youtubeId: "6JElrEbAcwY" },
  { artist: "Bill Wurtz", name: "La de Da de Da de Da de Day Oh", youtubeId: "atVsmnCX1-Q" },
  { artist: "Daler Mehndi", name: "Tunak Tunak Tun", youtubeId: "_T_MOxNGsks" },
  { artist: "Tom Petty", name: "Free Fallin'", youtubeId: "ikum949djQM" },
  { artist: "10cc", name: "The Things We Do For Love", youtubeId: "0qycxTpMb5U" },
  { artist: "Tears For Fears", name: "Everybody Wants To Rule The World", youtubeId: "Qs-8xYwYJAQ" },
  { artist: "Selena Gomez & The Scene", name: "Naturally", youtubeId: "50gktcuxdAA" },
  { artist: "Bachman-Turner Overdrive", name: "You Ain't Seen Nothing Yet", youtubeId: "w3fRBzRngdc" },
  { artist: "Rick Springfield", name: "Jessie's Girl", youtubeId: "4ejfFIj5rrA" },
  { artist: "SEGA, Kensuke Inage, 荒井 善博, 吉田 真理, Kensuke Inage", name: "Friday Night (Yakuza 0)", youtubeId: "JHbDuuCoWxk" },
  { artist: "Daft Punk", name: "Around the World", youtubeId: "-0srGzIW_5w" },
  { artist: "House Of Pain", name: "Jump Around", youtubeId: "Ps402yYQIm0" },
  { artist: "Madonna", name: "Holiday", youtubeId: "2Ngkn7zCObI" },
  { artist: "Goo Goo Dolls", name: "Slide", youtubeId: "KXRKoM0misA" },
  { artist: "Yota", name: "Limelight", youtubeId: "JA1rE0ezLvI" },
  { artist: "Nile Rodgers, Nataraj", name: "Never Surrender (Halo 2)", youtubeId: "EWEi_nSmohk" },
  { artist: "Don Henley", name: "All She Wants To Do Is Dance", youtubeId: "wFBHBSPvZbw" },
  { artist: "REO Speedwagon", name: "Take It On the Run", youtubeId: "NVtIgfqI6yo" },
  { artist: "ZZ Top", name: "Tush", youtubeId: "wTn6vYpVLN0" },
  { artist: "Alice Cooper", name: "Poison", youtubeId: "9K2ktd6Ajkc" },
  { artist: "M83", name: "Midnight City", youtubeId: "7ID-1jzek4Y" },
  { artist: "James Taylor", name: "Fire and Rain", youtubeId: "N8u8tASPtwg" },
  { artist: "Otis Redding", name: "[Sittin' On] the Dock of the Bay", youtubeId: "Zu5chXCp1Ag" },
  { artist: "Hilary Duff", name: "Come Clean", youtubeId: "bChbkVRX3jM" },
  { artist: "Kool & The Gang", name: "Jungle Boogie", youtubeId: "QGKiC2suCHQ" },
  { artist: "Gerry Rafferty", name: "Baker Street", youtubeId: "6tynWSAesAo" },
  { artist: "Smash Mouth", name: "All Star", youtubeId: "8ay_BkRuv-o" },
  { artist: "Don Henley", name: "The Boys Of Summer", youtubeId: "6RUIeX6UCT8" },
  { artist: "Rob Dougan", name: "Clubbed to Death (Kurayamino Variation)", youtubeId: "OwGuSPXPyX4" },
  { artist: "Chief Keef", name: "Laughin' To The Bank", youtubeId: "fqYjCXCqMbs" },
  { artist: "Naked Eyes", name: "Always Something There to Remind Me", youtubeId: "e2fQxbEshtA" },
  { artist: "Donna Summer", name: "She Works Hard For The Money", youtubeId: "09ZSKE38lTU" },
  { artist: "Dire Straits", name: "Walk of Life", youtubeId: "hylE_GwLFCM" },
  { artist: "twenty one pilots", name: "Jumpsuit", youtubeId: "YTPAHj4bPjA" },
  { artist: "The Communards", name: "Don't Leave Me This Way (feat. Sarah Jane Morris)", youtubeId: "O-M2RWPRN9E" },
  { artist: "Finger Eleven", name: "Paralyzer", youtubeId: "ZD3kCxMqjuk" },
  { artist: "Berlin", name: "Take My Breath Away (Love Theme from \"Top Gun\")", youtubeId: "F4M6haOFtcE" },
  { artist: "The Killers", name: "The Man", youtubeId: "0BS5lRJfJgQ" },
  { artist: "Beastie Boys", name: "Sabotage", youtubeId: "B8CD2Rco8cQ" },
  { artist: "Enrique Iglesias, Pitbull", name: "I Like It", youtubeId: "FAqhvV1k45E" },
  { artist: "Jackson Browne", name: "Running on Empty", youtubeId: "lFQKvtbD6Kw" },
  { artist: "Journey", name: "Lights", youtubeId: "BPazVAtD-xQ" },
  { artist: "Martin O’Donnell, Michael Salvatori, Stan LePard", name: "Skyline (Halo 3: ODST)", youtubeId: "IHxFTQYAqfc" },
  { artist: "MGMT", name: "Electric Feel", youtubeId: "r78xfXZb_WU" },
  { artist: "Billy Squier", name: "My Kinda Lover", youtubeId: "nI33SNkIsMw" },
  { artist: "Bob Seger & The Silver Bullet Band", name: "Night Moves", youtubeId: "ePCiVK5Jjos" },
  { artist: "Bryan Adams", name: "Cuts Like A Knife (Classic Version)", youtubeId: "4J_QbsH-Onk" },
  { artist: "Eddie Money", name: "Take Me Home Tonight", youtubeId: "FGb381tMJrw" },
  { artist: "M|A|R|R|S", name: "Pump Up The Volume", youtubeId: "w9gOQgfPW4Y" },
  { artist: "Boston", name: "Cool The Engines", youtubeId: "jw0YNvlFOrI" },
  { artist: "Bad Company", name: "Rock 'n' Roll Fantasy", youtubeId: "2Ha3L--3hrY" },
  { artist: "twenty one pilots", name: "Heavydirtysoul", youtubeId: "lzXRdS9cynQ" },
  { artist: "Eric Clapton", name: "I Shot The Sheriff", youtubeId: "L0xLLPJ0bOw" },
  { artist: "Aqua", name: "Barbie Girl", youtubeId: "77zog6Up_Yg" },
  { artist: "Survivor", name: "Is This Love", youtubeId: "1pgccRRuJOk" },
  { artist: "Bruce Springsteen", name: "Born in the U.S.A.", youtubeId: "tRx212PUa4g" },
  { artist: "Dido", name: "White Flag", youtubeId: "j-fWDrZSiZs" },
  { artist: "blink-182", name: "First Date", youtubeId: "-3mTma5zL2Y" },
  { artist: "Santana", name: "Evil Ways", youtubeId: "YiNME8msrzg" },
  { artist: "Oasis, Noel Gallagher", name: "Champagne Supernova", youtubeId: "MlZOFIRC9HA" },
  { artist: "Boston", name: "Rock & Roll Band", youtubeId: "n-pC3ncVjEw" },
  { artist: "Noisestorm", name: "Crab Rave", youtubeId: "c_sNBS-9Ras" },
  { artist: "Styx", name: "Babe", youtubeId: "ogicte-_dwA" },
  { artist: "Jim Croce", name: "Bad, Bad Leroy Brown", youtubeId: "JzYf6qskdfA" },
  { artist: "Michelle Branch", name: "All You Wanted", youtubeId: "54GTRiMDOYA" },
  { artist: "Enigma", name: "Return To Innocence (Radio Edit)", youtubeId: "7vUjpAz7Z5k" },
  { artist: "Darren Korb", name: "A Proper Story (Bastion)", youtubeId: "Z9y0iFVAbDg" },
  { artist: "Annie Lennox", name: "Walking on Broken Glass", youtubeId: "nSN0YysStgY" },
  { artist: "Eminem", name: "The Real Slim Shady", youtubeId: "r5MR7_INQwg" },
  { artist: "Wolfmother", name: "Joker And The Thief", youtubeId: "iR5RY7pnMGI" },
  { artist: "Nelly Furtado", name: "I'm Like A Bird", youtubeId: "QPvpSMkQsO4" },
  { artist: "Thomas Bergersen", name: "Protectors of the Earth", youtubeId: "Y54rf5FfO90" },
  { artist: "Atlanta Rhythm Section", name: "So Into You", youtubeId: "wzCdSJu5xqI" },
  { artist: "blink-182", name: "All The Small Things", youtubeId: "5_PBNZXlzp0" },
  { artist: "Greg Kihn Band", name: "Jeopardy", youtubeId: "5iZSlA4s5Xs" },
  { artist: "Queen", name: "Don't Stop Me Now", youtubeId: "RrDKoXWKMmY" },
  { artist: "KT Tunstall", name: "Suddenly I See", youtubeId: "eX7lFYAaO-c" },
  { artist: "The Clash", name: "I Fought the Law", youtubeId: "ed2ZwYbVk-4" },
  { artist: "Modern English", name: "I Melt with You (7\" Mix)", youtubeId: "Is-osO04u3c" },
  { artist: "Foreigner", name: "Feels like the First Time", youtubeId: "cDUEMvCKLis" },
  { artist: "Robbie Williams", name: "Rock DJ", youtubeId: "ZLyyT9yW9kA" },
  { artist: "Michelle Branch", name: "Everywhere", youtubeId: "UCzLhySDWME" },
  { artist: "Dire Straits", name: "Money for Nothing", youtubeId: "T82VtnfKGd8" },
  { artist: "Billy Idol", name: "White Wedding (Pt. 1)", youtubeId: "o5oTrvdNoP4" },
  { artist: "Alan Braxe, Fred Falke", name: "Rubicon", youtubeId: "uFCGrZHP2cI" },
  { artist: "Barenaked Ladies", name: "The Old Apartment", youtubeId: "Pv7kodfFXpA" },
  { artist: "Heart", name: "Magic Man", youtubeId: "fEqwrBkAdTM" },
  { artist: "\"Weird Al\" Yankovic", name: "Hardware Store", youtubeId: "DFI6cV9slfI" },
  { artist: "Chic", name: "Le Freak", youtubeId: "RAYQTfFh4xk" },
  { artist: "John Mellencamp", name: "R.O.C.K. In The U.S.A.", youtubeId: "NI4yOx6XGdM" },
  { artist: "Bryan Adams", name: "Run To You", youtubeId: "nCBASt507WA" },
  { artist: "ZZ Top", name: "Got Me Under Pressure", youtubeId: "tUCckLDCOrg" },
  { artist: "Boney M.", name: "Rasputin (Club Mix)", youtubeId: "6qMWRf0dU5s" },
  { artist: "The B-52's", name: "Love Shack", youtubeId: "LXZFtJceQC4" },
  { artist: "Santana, Chad Kroeger", name: "Into the Night", youtubeId: "-AwPbtYFe_8" },
  { artist: "ABBA", name: "Money, Money, Money", youtubeId: "1LPNuFkteI8" },
  { artist: "Kenny Loggins", name: "Playing with the Boys (From \"Top Gun\" Original Soundtrack)", youtubeId: "CWUqYzKCxvE" },
  { artist: "Tom Petty", name: "Runnin' Down A Dream", youtubeId: "XrzeL7SK5Fw" },
  { artist: "Tesla", name: "Signs", youtubeId: "w0NyH52NSms" },
  { artist: "The Doobie Brothers", name: "What a Fool Believes", youtubeId: "exnHAdopRxA" },
  { artist: "Red Hot Chili Peppers", name: "Otherside", youtubeId: "8901V1M5lDk" },
  { artist: "Thompson Twins", name: "Hold Me Now", youtubeId: "3c3P86kALbk" },
  { artist: "Peter Bjorn and John", name: "Young Folks", youtubeId: "OIRE6iw-ws4" },
  { artist: "Roxette", name: "The Look", youtubeId: "-1lHYs--Pik" },
  { artist: "Michael Jackson", name: "Thriller", youtubeId: "Z85lxckrtzg" },
  { artist: "Bo Burnham", name: "Bezos I", youtubeId: "7_EeCkHs-e0" },
  { artist: "Sum 41", name: "In Too Deep", youtubeId: "ecHf1zkYRVg" },
  { artist: "Tom Petty And The Heartbreakers", name: "Refugee", youtubeId: "8vDgheSFX04" },
  { artist: "Dolly Parton", name: "9 to 5", youtubeId: "E4OzdyxbOuU" },
  { artist: "Talking Heads", name: "Psycho Killer", youtubeId: "tqQGWhge5yo" },
  { artist: "T. Rex", name: "Bang a Gong (Get It On)", youtubeId: "gEeHOOudKOw" },
  { artist: "Def Leppard", name: "Rock Of Ages", youtubeId: "fTAOLmEba4I" },
  { artist: "Mike Posner", name: "Cooler Than Me (Single Mix)", youtubeId: "Bsr8hb3TPBI" },
  { artist: "Screaming Jay Hawkins", name: "Little Demon", youtubeId: "hpC-LAbkXTM" },
  { artist: "Huey Lewis & The News", name: "I Want A New Drug", youtubeId: "0PhqlB-uTX8" },
  { artist: "Cher", name: "Believe", youtubeId: "oOGxKLUMbbc" },
  { artist: "Coldplay", name: "Speed of Sound", youtubeId: "04tC72kZM_o" },
  { artist: "Pink Floyd", name: "Money", youtubeId: "rwPM01cbQBc" },
  { artist: "Madness", name: "Our House", youtubeId: "r0KzvXf-_Zw" },
  { artist: "Devo", name: "Whip It", youtubeId: "3bXEHGUvVrA" },
  { artist: "Kenny Loggins", name: "Danger Zone (From \"Top Gun\" Original Soundtrack)", youtubeId: "9ID4RAqCqnQ" },
  { artist: "Pink Floyd", name: "Learning To Fly", youtubeId: "vcFXq5-XFeE" },
  { artist: "Huey Lewis & The News", name: "The Heart Of Rock & Roll (Single Edit)", youtubeId: "z8rZ_iS2vX8" },
  { artist: "Journey", name: "Who's Crying Now", youtubeId: "X5pJYBjcKeI" },
  { artist: "Status Quo", name: "Rockin' All Over The World", youtubeId: "1cJGL9qEYUE" },
  { artist: "Tom Petty", name: "I Won't Back Down", youtubeId: "Is4uUP11NxE" },
  { artist: "BattleBlock Theater Music", name: "Secret Area", youtubeId: "zwZISypgA9M" },
  { artist: "Young MC", name: "Bust A Move", youtubeId: "6rM8ksGHnFc" },
  { artist: "Steve Miller Band", name: "Rock'n Me", youtubeId: "baWTjg1QqS8" },
  { artist: "Blondie", name: "Call Me", youtubeId: "tykf2xYPNdc" },
  { artist: "Foreigner", name: "Head Games", youtubeId: "CbHe-R3J7q8" },
  { artist: "Creedence Clearwater Revival", name: "Green River", youtubeId: "w0VI1zByJ3s" },
  { artist: "Jaycut, Kolt Siewerts, Soulpride", name: "The Flute Tune (Soulpride Remix)", youtubeId: "asnnRxedHVc" },
  { artist: "Iyaz", name: "Replay", youtubeId: "4FqGpT3yhUI" },
  { artist: "Bruce Springsteen", name: "Dancing In the Dark", youtubeId: "nCFTL4IO6t4" },
  { artist: "Styx", name: "Renegade", youtubeId: "E9eLz4DrwF8" },
  { artist: "American Hi-Fi", name: "Flavor Of The Weak", youtubeId: "B-mi4SdTomM" },
  { artist: "Earth, Wind & Fire, The Emotions", name: "Boogie Wonderland", youtubeId: "2Hp8adWkSFU" },
  { artist: "Bryan Adams", name: "Summer Of '69", youtubeId: "eFjjO_lhf9c" },
  { artist: "Matchbox Twenty", name: "3AM", youtubeId: "hH4wcNRj9PI" },
  { artist: "Survivor", name: "High on You", youtubeId: "coGPSIq1_NI" },
  { artist: "The Knack", name: "My Sharona", youtubeId: "ZR-3r5Z9kqw" },
  { artist: "Joan Jett", name: "Bad Reputation", youtubeId: "x6fB8KMUnz0" },
  { artist: "Nitro Fun", name: "New Game (Just Shapes & Beats)", youtubeId: "ZVkPmLt1qrM" },
  { artist: "Kiss", name: "I Was Made For Lovin' You", youtubeId: "12fJAnaif34" },
  { artist: "_ensnare_", name: "There Is Always Love", youtubeId: "jD6QXl1_Fds" },
  { artist: "Myrone", name: "Zadok", youtubeId: "04JrAnWnN5k" },
  { artist: "Sandra", name: "Maria Magdalena", youtubeId: "5B8a9WcYC6A" },
  { artist: "Creedence Clearwater Revival", name: "Who'll Stop The Rain", youtubeId: "yGc6qKflSWk" },
  { artist: "Elton John, Kiki Dee", name: "Don't Go Breaking My Heart", youtubeId: "DH7kBuZ5GZk" },
  { artist: "Cher", name: "Gypsys, Tramps & Thieves", youtubeId: "bv-o_24MZfU" },
  { artist: "Badfinger", name: "Day After Day", youtubeId: "-IiWtFY-vGE" },
  { artist: "Bee Gees", name: "More Than A Woman", youtubeId: "ki5i2oxD2Eg" },
  { artist: "Red Hot Chili Peppers", name: "Californication", youtubeId: "A__cH65WRvE" },
  { artist: "Living In A Box", name: "Living in a Box", youtubeId: "_7iFGOtWrjs" },
  { artist: "Fleetwood Mac", name: "Rhiannon", youtubeId: "0xGPi-Al3zQ" },
];
